import { Box } from '~components/atoms/Box';
import {
  RadioGroupRoot,
  StyledRadioGroupItem,
  StyledRadioGroupItemIndicator,
} from '~components/atoms/RadioGroup';
import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import {
  StyledOddsContainer,
  StyledSettingsPopoverContent,
  StyledSettingsPopoverDesktopLabel,
  StyledSettingsPopoverItem,
} from '~components/atoms/SettingsDropdown/styled.components';
import { Text } from '~components/atoms/Typography';
import { ODDS } from '~constants/odds';
import { TIMEZONES } from '~constants/timezones';
import { useTranslation } from '~hooks/useTranslation';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { zIndex } from '~utils/zIndex';

import { flagsMap, langOptions } from './SettingsDropdownPopover';

export const SettingsDropdownPopoverDesktop = () => {
  const { localized } = useTranslation();

  const {
    odds,
    timezone,
    onTimezoneChange,
    onOddsChange,
    onLanguageChange,
    language,
  } = useWebsiteSettings();

  const showLanguage = false;

  return (
    <StyledSettingsPopoverContent>
      <StyledSettingsPopoverItem
        css={{ padding: '12px 16px', flexDirection: 'row' }}
      >
        {showLanguage && (
          <div style={{ width: '240px' }}>
            <StyledSettingsPopoverDesktopLabel
              level="14-20"
              textTransform="uppercase"
              color="textSecondaryOne"
            >
              {localized('settings.language')}
            </StyledSettingsPopoverDesktopLabel>
            <SelectWithLabel
              value={language}
              setValue={onLanguageChange}
              options={langOptions}
              additionalSelectedComponents={flagsMap}
              dropdownWidth="230px"
              style={{ width: '100%' }}
              dropdownContentStyles={{
                zIndex: zIndex.modal + 1,
              }}
            />
          </div>
        )}
        <div style={{ width: '240px' }}>
          <StyledSettingsPopoverDesktopLabel
            level="14-20"
            textTransform="uppercase"
            color="textSecondaryOne"
          >
            {localized('settings.timeZone')}
          </StyledSettingsPopoverDesktopLabel>
          <SelectWithLabel
            value={timezone}
            setValue={onTimezoneChange}
            options={TIMEZONES}
            style={{
              width: '100%',
              borderRadius: '$6',
              '> .ant-select-selector': {
                backgroundColor: '$secondaryTwo !important',
              },
            }}
          />
        </div>
      </StyledSettingsPopoverItem>
      <StyledSettingsPopoverItem>
        <StyledSettingsPopoverDesktopLabel
          level="14-20"
          textTransform="uppercase"
          color="textSecondaryOne"
        >
          {localized('settings.oddsFormat')}
        </StyledSettingsPopoverDesktopLabel>
        <RadioGroupRoot value={odds as string} onValueChange={onOddsChange}>
          <StyledOddsContainer>
            {ODDS.map(({ value, label }) => (
              <Box flexRow alignCenter gap={2} key={value}>
                <StyledRadioGroupItem value={value}>
                  <StyledRadioGroupItemIndicator />
                </StyledRadioGroupItem>
                <Text level="14-24">{label}</Text>
              </Box>
            ))}
          </StyledOddsContainer>
        </RadioGroupRoot>
      </StyledSettingsPopoverItem>
    </StyledSettingsPopoverContent>
  );
};
