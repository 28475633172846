export const MOBILE_TAB_HEIGHT = 52;

export const MOBILE_TAB_ROUND_DECORATION_HEIGHT = 18;

export const IFRAME_HEADER_HEIGHT = 72;

export const IFRAME_HEADER_MARGIN = 4;

export const HEADER_HEIGHT = 146.5;

export const LAPTOP_HEADER_HEIGHT = 142.5;

export enum THEMES {
  DEFAULT = 'default',
  SEMA_BET = 'semabet',
  BET_ONLY = 'betonly',
  OTBET = 'otbet',
  BETPRAZER = 'betprazer',
}

export enum PARTNERS {
  BETONLY = 'betonly',
  SEMABET = 'semabet',
  BETPRAZER = 'betprazer',
  OTBET = 'otbet',
}

export const getSportPageElementsStyles = () => {
  const isInIframe = window.self !== window.top;
  const isLive = window.location.href.includes('menu=live');

  return {
    height: isInIframe
      ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
      : `calc(100vh - ${HEADER_HEIGHT + (isLive ? 52 : 4)}px)`,
    '@md': {
      height: isInIframe
        ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
        : `calc(100vh - ${LAPTOP_HEADER_HEIGHT + (isLive ? 44 : 4)}px)`,
    },
  };
};
