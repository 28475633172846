import React, { useMemo, useRef, useState } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import {
  StyledNumberInputContainer,
  StyledPureIconButton,
} from '~components/atoms/NumberInput/styled.components';
import { useNumberInput } from '~components/atoms/NumberInput/useNumberInput';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { useMedia } from '~hooks/useMedia';
import { MinusIcon, PlusIcon } from '~icons';
import { useAppSelector } from '~store';

const StyledValueMultiplierPlaceholder = styled(Box, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '$3',
  color: '$textSecondaryOne',
});

interface NumberInputProps {
  value: string;
  onChange: (value: string) => void;
  withoutActions?: boolean;
  fullWidth?: boolean;
  textColor?: string;
  inputColor?: string;
  isPrimaryColor?: boolean;
}

export const NumberInput = ({
  onChange: onSuccess,
  fullWidth = false,
  value: initialValue,
  withoutActions = false,
  textColor,
  inputColor,
  isPrimaryColor = false,
}: NumberInputProps) => {
  const { isMobileOrTablet } = useMedia();
  const numberInputRef = useRef<HTMLDivElement>();
  const [isFocused, setIsFocused] = useState(false);

  const { enableDecimals } = useAppSelector((state) => state.settings);
  const { stakeType, systemBetOption } = useAppSelector(
    (state) => state.betslip,
  );

  const { value, onIncrement, onDecrement, onChange } = useNumberInput({
    onSuccess,
    initialValue,
    enableDecimals,
  });

  const showMultiplier =
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM && systemBetOption;

  const onClick = () => {
    if (numberInputRef.current && isMobileOrTablet) {
      numberInputRef?.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // Don't show 0 if user is focused on input
  const getInputValue = () => {
    if (isFocused && value === '') return '';
    if (value === '') return 0;

    return value;
  };

  const numberInputComponent = useMemo(
    () => (
      <Input
        textCentered
        inputMode={enableDecimals ? 'decimal' : 'numeric'}
        value={getInputValue()}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        css={{
          color: textColor || '$textPrimaryOne',
          backgroundColor: inputColor || '$primaryTwo',
          fontWeight: '$medium',
          '@xs_sm': {
            height: '32px',
          },
        }}
      />
    ),
    [
      getInputValue,
      onChange,
      handleFocus,
      handleBlur,
      enableDecimals,
      textColor,
      inputColor,
    ],
  );

  return (
    <StyledNumberInputContainer
      ref={numberInputRef}
      fullWidth={fullWidth}
      isPrimaryColor={isPrimaryColor}
    >
      {!withoutActions && (
        <StyledPureIconButton
          left
          isPrimaryColor={isPrimaryColor}
          onClick={onDecrement}
        >
          <MinusIcon />
        </StyledPureIconButton>
      )}

      <button
        onClick={onClick}
        style={{
          padding: 0,
          border: 'none',
          borderRadius: 'inherit',
          width: '100%',
          background: 'transparent',
        }}
      >
        {showMultiplier ? (
          <Box
            css={{
              position: 'relative',
            }}
          >
            {numberInputComponent}
            <StyledValueMultiplierPlaceholder>
              {`X${systemBetOption.combinationsCount}`}
            </StyledValueMultiplierPlaceholder>
          </Box>
        ) : (
          numberInputComponent
        )}
      </button>
      {!withoutActions && (
        <StyledPureIconButton
          right
          isPrimaryColor={isPrimaryColor}
          onClick={onIncrement}
        >
          <PlusIcon />
        </StyledPureIconButton>
      )}
    </StyledNumberInputContainer>
  );
};
