import React from 'react';

import { VerifyEmailTokenResponse } from '~api/auth/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Loader } from '~components/atoms/Loader';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { VerifyEmailFailIcon, VerifyEmailSuccessIcon } from '~icons';

import { StyledVerifyEmailToken } from './styled.components';

interface VerifyEmailTokenContentProps {
  isSuccess: boolean | undefined;
  isLoading: boolean;
  isMobileOrTablet: boolean;
  verifyEmailTokenData: VerifyEmailTokenResponse | undefined;
  localized: (key: string) => string;
  handleModalClose: () => void;
}

export const VerifyEmailTokenContent = ({
  isSuccess,
  isLoading,
  isMobileOrTablet,
  localized,
  verifyEmailTokenData,
  handleModalClose,
}: VerifyEmailTokenContentProps) => {
  return (
    <StyledVerifyEmailToken>
      {!isLoading && verifyEmailTokenData ? (
        <>
          <Box
            css={{
              mb: isMobileOrTablet ? '11px' : '$4',
              lineHeight: 0,
            }}
          >
            {isSuccess ? (
              isMobileOrTablet ? (
                <VerifyEmailSuccessIcon width={26} height={26} />
              ) : (
                <VerifyEmailSuccessIcon />
              )
            ) : isMobileOrTablet ? (
              <VerifyEmailFailIcon width={26} height={26} />
            ) : (
              <VerifyEmailFailIcon />
            )}
          </Box>
          <Box css={{ mb: isMobileOrTablet ? '$1' : '$2' }}>
            <Text
              level={isMobileOrTablet ? '14-20' : '17-24'}
              fontWeight="medium"
            >
              {localized(
                `verifyEmailToken.${isSuccess ? 'success' : 'fail'}.title`,
              )}
            </Text>
          </Box>
          <Box css={{ mb: isMobileOrTablet ? '$2' : '$6' }}>
            <Text level={isMobileOrTablet ? '12-20' : '14-20'}>
              {localized(
                `verifyEmailToken.${isSuccess ? 'success' : 'fail'}.subtitle`,
              )}
            </Text>
          </Box>
          {isMobileOrTablet && (
            <Separator
              verticalSpace={0}
              shrinkOut={0}
              css={{
                backgroundColor: '$secondaryOne',
                width: 'calc(100% + (2 * $3))',
                mb: '$3',
              }}
            />
          )}
          <Button
            fullWidth
            variant="secondary"
            onClick={handleModalClose}
            css={{
              p: '$2',
              '@xs_sm': {
                borderRadius: '$6',
              },
            }}
          >
            <Text level="18-24" textAlign="center">
              {localized(`verifyEmailToken.button.continue`)}
            </Text>
          </Button>
        </>
      ) : (
        <Loader
          css={{
            color: '$primaryTwo',
            mt: '$10',
            '& > svg': {
              width: '$8',
              height: '$8',
            },
          }}
        />
      )}
    </StyledVerifyEmailToken>
  );
};
