import { ComponentPropsWithRef, forwardRef } from 'react';
import { CSS } from '@stitches/react';

import { Box } from '~components/atoms/Box';
import { GrayCrossIcon } from '~icons';

interface CrossProps extends ComponentPropsWithRef<'div'> {
  css?: CSS;
}

export const Cross = forwardRef<HTMLDivElement, CrossProps>(
  ({ css, ...props }, ref) => {
    return (
      <Box
        css={{
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          p: '$2',
          lineHeight: 0,
          '&:hover': {
            cursor: 'pointer',
            color: '$hoverPrimary',
          },
          '&:active': {
            color: '$pressedOne',
          },

          ...css,
        }}
        ref={ref}
        {...props}
      >
        <GrayCrossIcon />
      </Box>
    );
  },
);
