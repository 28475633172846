import React from 'react';
import { CSS } from '@stitches/react';

import { Box } from '~components/atoms/Box';

interface StyledBaseInputProps {
  children: React.ReactNode;
  css?: CSS;
}

export const StyledBaseSelect = ({ children, css }: StyledBaseInputProps) => {
  return (
    <Box
      flexRow
      alignCenter
      fullWidth
      css={{
        ...css,
        backgroundColor: '$primaryTwo',
        borderRadius: '$6',
        '& > button': {
          p: '0 $3',
          backgroundColor: '$primaryTwo',
          height: '$6',
          width: '100%',
        },
        '& > button:hover': {
          outline: 'none',
          boxShadow: 'none',
        },
        '& > button > span:last-child': {
          marginRight: '$1',
        },
        '@xs_sm': {
          height: '$7',
        },
      }}
    >
      {children}
    </Box>
  );
};
