import React, { useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { Tooltip } from '~components/atoms/Tooltip';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_CODES, genderOptions } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { ProtectedChoiceIcon } from '~icons';
import { useAppSelector } from '~store';
import { ellipsis } from '~utils/cssUtils';

import {
  PERSONAL_DETAILS_FORM_FIELDS,
  PERSONAL_DETAILS_I18N_TITLES,
} from './PersonalDetailsForm/constants';
import { StyledDetailField } from './UserDetailEditableField';

interface UserCellProps {
  title: keyof typeof PERSONAL_DETAILS_I18N_TITLES;
  data: string | number | null;
  isProtected?: boolean;
}

export const UserDetailField = ({
  title,
  data: dataVal,
  isProtected = true,
}: UserCellProps) => {
  const { localized } = useTranslation();
  const localizedGenderOptions = useMemo(() => genderOptions(localized), []);

  const { currency } = useAppSelector((state) => state.settings);
  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isZambia = currency === CURRENCY_CODES.ZMK;

  let data = dataVal;

  if (title === PERSONAL_DETAILS_FORM_FIELDS.USER_GENDER_FIELD) {
    data =
      (localizedGenderOptions!.find((item) => item.value == dataVal)
        ?.label as string) || '';
  }

  return (
    <StyledDetailField flexRow alignCenter justifyContentBetween gap={2}>
      <Text level="sm-3" color="textSecondaryOne">
        {(isNigeria || isZambia) && title === 'userIdField'
          ? 'NIN'
          : localized(PERSONAL_DETAILS_I18N_TITLES[title])}
      </Text>
      <Box
        flexRow
        alignCenter
        gap={3}
        css={{
          ...ellipsis,
        }}
      >
        <Text level="sm-3" ellipsis>
          {data}
        </Text>
        {isProtected && (
          <Tooltip
            text={localized(
              'userProfile.personalProfileInfo.yourFieldWasVerified',
              {
                fieldName: localized(
                  PERSONAL_DETAILS_I18N_TITLES[title],
                ).toLowerCase(),
              },
            )}
          >
            <Box flexRow css={{ color: '$textPrimaryOne' }}>
              <ProtectedChoiceIcon width={18} height={18} />
            </Box>
          </Tooltip>
        )}
      </Box>
    </StyledDetailField>
  );
};
