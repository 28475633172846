import React, { forwardRef } from 'react';
import { styled } from 'stitches.config';

const InputPrimitive = forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithRef<'input'>
>((props, ref) => <input ref={ref} {...props} />);

export const Input = styled(InputPrimitive, {
  display: 'block',
  fontFamily: 'inherit',
  fontSize: '$sm',
  fontWeight: '$normal',
  color: '$textPrimaryOne',
  caretColor: '$textPrimaryOne',
  border: 'none',
  p: '$0 $9 $0 $3',
  borderRadius: '$6',
  backgroundColor: '$secondaryTwo',
  width: '100%',
  lineHeight: '$36',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '$textSecondaryOne',
    opacity: 1,
  },
  variants: {
    phoneNumber: {
      true: {
        p: '$0 $9 $0 $15',
      },
    },
    protectedPhoneNumber: {
      true: {
        p: '2px 0 0 62px !important',
      },
    },
    search: {
      true: {
        backgroundColor: '$secondaryOne',
        py: '$1',
        pl: '32px',
        pr: '28px',
        lineHeight: '$24',
      },
    },
    textCentered: {
      true: {
        textAlign: 'center',
        p: 0,
      },
    },
    personalDetail: {
      true: {
        width: '200px',
        height: '34px',
        color: '$textPrimaryOne',
        backgroundColor: '$primaryTwo',
        pRight: '0',
        '&:focus': {
          boxShadow: '$elementActiveBorder',
        },
      },
    },
    inputBorderFocus: {
      true: {
        '&:focus': {
          boxShadow: '$elementActiveBorder',
        },
      },
    },
  },
  compoundVariants: [
    {
      phoneNumber: true,
      personalDetail: true,
      css: {
        width: '175px',
      },
    },
  ],
});

Input.displayName = 'Input';
