import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Label } from '~components/atoms/Label';
import { PasswordRequirementsPopover } from '~components/atoms/PasswordRequirementsPopover';
import { PasswordStrengthIndicator } from '~components/atoms/PasswordStrengthIndicator';
import { Separator } from '~components/atoms/Separator';
import { ErrorMessage, Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { usePasswordCharacteristics } from '~hooks/usePasswordCharacteristics';
import { useTranslation } from '~hooks/useTranslation';
import { EyeIcon, EyeSlashIcon } from '~icons';
import { removeEmptySpaces } from '~utils/sanitizers';

import {
  RESET_PASSWORD_FORM_FIELDS,
  ResetPasswordFormInputs,
} from '../../types';

import { ResetPasswordTabList } from './ResetPasswordTabList';

interface EnterNewPasswordTabProps {
  resetPasswordIsLoading: boolean;
  onChangePassword: () => void;
}

export const EnterNewPasswordTab = ({
  resetPasswordIsLoading,
  onChangePassword,
}: EnterNewPasswordTabProps) => {
  const { isMobile } = useMedia();

  const { register, watch, formState, trigger, clearErrors, setValue } =
    useFormContext<ResetPasswordFormInputs>();
  const { errors } = formState;
  const { newPasswordField } = watch();
  const { localized, localizedError } = useTranslation();

  const [isPasswordPopoverOpen, setIsPasswordPopoverOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { isValidPassword, localizedCharacteristicsItems, passwordStrength } =
    usePasswordCharacteristics(newPasswordField);

  const handleChangePassword = async () => {
    const output = await trigger();

    if (output) {
      onChangePassword();
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = removeEmptySpaces(event.target.value);

    clearErrors(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD);
    setValue(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD, sanitizedValue);
  };

  return (
    <>
      <Text
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
        css={{
          fontSize: '$xl',
          lineHeight: '$44',
          mb: '$2',
        }}
      >
        {localized('resetPassword.createNewPassword')}
      </Text>
      <Text
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
        css={{
          mb: '28px',
          fontSize: '17px',
          lineHeight: '$24',
        }}
      >
        {localized('resetPassword.useInAuthorization')}
      </Text>
      <ResetPasswordTabList />
      <Box>
        <Box flexRow justifyContentBetween>
          <Label htmlFor={RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD}>
            {localized('resetPassword.fields.newPassword.label')}
          </Label>
          {!!newPasswordField &&
            !errors.newPasswordField &&
            isValidPassword && (
              <PasswordStrengthIndicator
                strength={passwordStrength}
                css={{ margin: '0 $3 $1 $2' }}
              />
            )}
          {!!errors.newPasswordField && (
            <ErrorMessage message={errors.newPasswordField.message!} />
          )}
        </Box>
        <Box
          css={{
            position: 'relative',
          }}
        >
          <Input
            id={RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD}
            placeholder={localized(
              'resetPassword.fields.newPassword.placeholder',
            )}
            {...register(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD, {
              validate: {
                passwordRequirementsError: () => {
                  return (
                    isValidPassword ||
                    localizedError('password.requirementsNotMet')
                  );
                },
              },
            })}
            onFocus={() => setIsPasswordPopoverOpen(true)}
            onBlur={() => setIsPasswordPopoverOpen(false)}
            onChange={handlePasswordChange}
            type={showPassword ? 'text' : 'password'}
          />
          <Box
            css={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              p: '$2 $3',
              cursor: 'pointer',
              color: '$textSecondaryOne',
              '&:hover': {
                color: '$textPrimaryOne',
              },
            }}
            role="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
          </Box>
          <PasswordRequirementsPopover
            isPasswordPopoverOpen={isPasswordPopoverOpen}
            localizedCharacteristicsItems={localizedCharacteristicsItems}
          />
        </Box>
      </Box>
      <Separator
        verticalSpace={isMobile ? 3 : 6}
        shrinkOut={isMobile ? 4 : 7}
      />
      <Button
        fullWidth
        onClick={handleChangePassword}
        isLoading={resetPasswordIsLoading}
      >
        {localized('buttons.changePassword')}
      </Button>
    </>
  );
};
