import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledDisconnectedContainer = styled(Box, {
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  position: 'fixed',
  backdropFilter: 'blur(4px)',
  background: 'rgba(10, 15, 20, 0.70)',
  zIndex: -1,
  opacity: 0,
  variants: {
    isDisconnected: {
      true: {
        zIndex: 1001,
        opacity: 1,
      },
    },
  },
});

export const StyledDisconnectedContent = styled(Box, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1001,
  color: '$textPrimaryOne',
  background: '$primaryOne',
  p: '2rem 0 2rem 0',
  gap: '$2',
  '& > svg': {
    width: '3rem',
    height: '3rem',
  },
  '@xs_sm': {
    '& > svg': {
      width: '1.75rem',
      height: '1.75rem',
    },
    gap: '$1',
    padding: '$5 52px $4 52px',
  },
});

export const StyledReconnectedContainer = styled(Box, {
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  padding: '$4',
  background: '#083E20',
  borderBottom: '4px solid #048531',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1001,
  '@xs_sm': {
    padding: '$2',
  },
});

export const StyledRedBorder = styled(Box, {
  background: '$redPrimary',
  width: '100vw',
  height: '1px',
  marginBottom: '$5',
  marginTop: '$4',
  '@xs_sm': {
    marginBottom: '$4',
    marginTop: '$3',
  },
});
