import React, { useState } from 'react';
import { Select } from 'antd';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { CheckedIcon, ChevronDownIcon, RedCrossIcon, SearchIcon } from '~icons';

interface Option {
  label: string;
  value: string;
}

interface AutocompleteProps {
  options: Option[];
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
}

export const Autocomplete = ({
  options,
  onChange,
  value,
  placeholder,
}: AutocompleteProps) => {
  const { localized } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || null);
  const [searchValue, setSearchValue] = useState('');
  const handleSelect = (value: string, option: Option | undefined) => {
    if (option) {
      setSelectedValue(value);
      setIsOpened(false);
      onChange(value);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box
      fullWidth
      css={{
        '& .ant-select': {
          width: '100% !important',
        },
        '& .ant-select-selector > span': {
          marginLeft: '$2',
        },
      }}
    >
      <Select
        value={selectedValue}
        searchValue={searchValue}
        onSearch={handleSearchChange}
        onSelect={handleSelect}
        options={options}
        showSearch
        allowClear={{
          clearIcon: <RedCrossIcon width={14} height={14} />,
        }}
        notFoundContent={
          <Text
            color="primaryTwo"
            level="14-24"
            css={{ width: '100%', textAlign: 'center' }}
          >
            {localized('noResults')}
          </Text>
        }
        placeholder={placeholder}
        menuItemSelectedIcon={<CheckedIcon />}
        onDropdownVisibleChange={setIsOpened}
        open={isOpened}
        onClear={() => {
          setSelectedValue(null);
        }}
        suffixIcon={
          <Box css={{ color: '$textSecondaryOne' }}>
            {selectedValue ? null : isOpened ? (
              <SearchIcon />
            ) : (
              <ChevronDownIcon />
            )}
          </Box>
        }
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
      />
    </Box>
  );
};
