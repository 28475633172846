import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { ProtectedChoiceIcon } from '~icons';

export type VerificationType = 'verified' | 'partiallyVerified' | 'notVerified';

interface VerificationContentProps {
  type: VerificationType;
}

export const UserVerificationContent = ({ type }: VerificationContentProps) => {
  const { localized } = useTranslation();

  if (type === 'verified') {
    return (
      <>
        <Text color="greenPrimary" level="sm-3">
          {localized('userProfile.personalProfileInfo.accountVerified')}
        </Text>
        <Box flexRow css={{ color: 'greenPrimary' }}>
          <ProtectedChoiceIcon width={16} height={16} />
        </Box>
      </>
    );
  } else if (type === 'partiallyVerified') {
    return (
      <Text level="sm-3">
        {localized('userProfile.personalProfileInfo.accountPartiallyVerified')}
      </Text>
    );
  } else {
    return (
      <Text color="redSecondary" level="sm-3">
        {localized('userProfile.personalProfileInfo.accountNotVerified')}
      </Text>
    );
  }
};
