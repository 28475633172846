import React from 'react';

import { UserProfileData } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import {
  CopyToClipboardIcon,
  EyeIcon,
  EyeSlashIcon,
  GreenCheckIcon,
  UserProfileIcon,
} from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { selectIsUganda } from '~store/slices/selectors/settings';
import { setBalanceVisibility } from '~store/slices/userUISlice';
import { ellipsis } from '~utils/cssUtils';

import {
  UserVerificationContent,
  VerificationType,
} from './UserVerificationContent';

export const UserGeneralInfoSection = ({
  profile,
}: {
  profile: UserProfileData | null;
}) => {
  const { handleCopy, isCopied } = useCopyToClipboard();
  const dispatch = useAppDispatch();
  const { isBalanceVisible } = useAppSelector((state) => state.userUIState);
  const isUganda = useAppSelector(selectIsUganda);

  if (!profile) {
    return null;
  }

  const {
    userName,
    phoneNumber,
    id,
    personalInfoConfirmed,
    emailConfirmed,
    phoneNumberConfirmed,
  } = profile;

  let verificationType: VerificationType;

  if (personalInfoConfirmed && emailConfirmed && phoneNumberConfirmed) {
    verificationType = 'verified';
  } else if (emailConfirmed || personalInfoConfirmed) {
    verificationType = 'partiallyVerified';
  } else {
    verificationType = 'notVerified';
  }

  return (
    <Box flexCol gap={3}>
      {!isUganda && (
        <Box flexRow gap={1} alignCenter>
          <UserVerificationContent type={verificationType} />
        </Box>
      )}
      <Box flexRow alignCenter gap={3}>
        <Box css={{ flexShrink: 0, color: '$textPrimaryOne' }}>
          <UserProfileIcon height={40} width={40} />
        </Box>
        <Box
          flexCol
          gap={2}
          alignStart
          css={{
            width: '160px',
            ...ellipsis,
            '@xs_sm': {
              width: '100%',
            },
          }}
        >
          <Box flexRow gap={2} alignCenter>
            <Text level="12-20">{userName || phoneNumber}</Text>
            <Box
              flexCol
              css={{
                cursor: 'pointer',
                color: '$textSecondaryOne',
                width: '$4',
                height: '$4',
                marginTop: isBalanceVisible ? '-1px' : '0',
              }}
              onClick={() => dispatch(setBalanceVisibility(!isBalanceVisible))}
            >
              {isBalanceVisible ? (
                <EyeIcon width={20} height={20} />
              ) : (
                <EyeSlashIcon width={20} height={20} />
              )}
            </Box>
          </Box>
          <Box flexRow gap={2} alignCenter css={{ width: '100%' }}>
            <Text level="12-20" ellipsis>
              ID: {id}
            </Text>
            <Box
              css={{
                display: 'flex',
                cursor: 'pointer',
                color: '$textSecondaryOne',
                width: '$3',
              }}
              onClick={() => {
                handleCopy(id);
              }}
            >
              {isCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
