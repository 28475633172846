import * as Accordion from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { ellipsis } from '~utils/cssUtils';

export const StyledDataTableContainer = styled(Box, {
  overflowY: 'auto',
  borderRadius: '$8',
  border: '1px solid $secondaryTwo',
  textAlign: 'left',
  height: '453px',
  '& .infinite-scroll-component': {
    overflow: 'hidden !important',
  },
  'tbody > td': {
    padding: '0 4px',
  },
});

export const StyledBetHistoryHeader = styled('th', {
  color: '$textSecondaryOne',
  fontSize: '$xxs',
  lineHeight: '$20',
  fontWeight: '$normal',
  padding: '$2 $0 $2 $2',
  borderBottom: '1px solid $secondaryTwo',
  '&:first-child': {
    borderRadius: '$8 0 0 0',
    padding: '$2 $0 $2 $6',
  },
  '&:last-child': {
    borderRadius: '0 $8 0 0',
  },
});

export const StyledCellComponent = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  background: '$primaryTwo',
  minHeight: '52px',
  ...ellipsis,
});

export const StyledInnerTableTBody = styled('tbody', {
  '& td': {
    background: '$primaryTwo',
    padding: '$1 0',
  },
});

export const StyledTable = styled('table', {
  width: '728px',
  border: 'none',
  borderCollapse: 'collapse',
  overflowX: 'hidden',

  tbody: {
    // Styles of row that is expanded
    '> tr.expanded': {
      td: {
        paddingBottom: '0 !important',

        '&:first-child': {
          '> div': {
            borderRadius: '8px 0px 0px 0px',
          },
        },

        '&:last-child': {
          '> div': {
            borderRadius: '0px 8px 0px 0px',
          },
        },
      },
    },

    // Styles of all non-expanded rows
    '> tr': {
      // General styles of all cells
      td: {
        color: '$textPrimaryOne',
        fontSize: '$12',
        lineHeight: '$20',

        // Padding that is responsible for spaces between rows
        padding: '$2 0 0 0',

        // Direct child div inside td, to create space between cells content
        '> div': {
          paddingLeft: '$2',
        },

        // Styles for the first cell in each row
        '&:first-child': {
          paddingLeft: '$3',
          '> div': {
            borderRadius: '8px 0px 0px 8px',
            padding: '$1 0 $1 $3',
          },
        },

        // Styles for the last cell in each row
        '&:last-child': {
          paddingRight: '$3',
          '> div': {
            borderRadius: '0px 8px 8px 0px',
            paddingRight: '$3',
          },
        },

        // If selection has status 'void', change text color to gray
        '&.void': {
          p: {
            color: '$textSecondaryOne',
          },
        },

        // Expandable table renders in the additional <tr> to the parent row, in <td> that occupies full width of the <tr>
        // Styles for expandable content table
        '&.expandableContent': {
          paddingTop: '0',

          /// Rows inside expanded content table
          td: {
            padding: '$1 0',
          },

          '> div': {
            padding: '0',
          },
        },
      },

      // Last row expanded content
      '&:last-child': {
        td: {
          paddingBottom: '$2 !important',
        },
      },
    },
  },
});

export const StyledTableRow = styled('tr', {
  // this height is always ignored, it is a trick to set full height for the <div> element inside <td>
  cursor: 'pointer',
});

export const StyledExpandedTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',

  tr: {
    '&:last-child': {
      td: {
        borderBottom: 'none',
      },
    },
  },

  td: {
    borderBottom: '1px solid $primaryTwo',
  },
});

export const StyledIconBox = styled(Box, {
  paddingRight: '$1',
  color: '$textSecondaryOne',
  transition: 'transform 0.4s ease-in-out',
  '&:hover, &:active, &.open': {
    color: '$textPrimaryOne',
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    bottom: '-10px',
    left: '-10px',
    right: '-10px',
    zIndex: -1,
  },
  variants: {
    isOpen: {
      true: {
        transform: 'rotate(180deg)',
      },
      false: {
        transform: 'rotate(0deg)',
      },
    },
  },
});

export const StyledCollapsibleContent = styled(Accordion.Content, {
  overflow: 'hidden',
  borderBottomRightRadius: '$6',
  borderBottomLeftRadius: '$6',
  backgroundColor: '$secondaryTwo',
});

export const StyledTableText = styled(Text, {
  fontSize: '12px !important',
  lineHeight: '20px !important',
});
