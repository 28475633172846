import React, { useState } from 'react';

import { useCallUserBack } from '~api/user/userMutations';
import { Box } from '~components/atoms/Box';
import { InputWithButton } from '~components/atoms/InputWithButton';
import { PhoneNumberInput } from '~components/atoms/PhoneNumberInput';
import { ErrorData } from '~constants/payments';
import { usePhoneNumber, useTranslation } from '~hooks';
import {
  StyledSupportText,
  StyledTitleText,
  StyledTopSectionWrapper,
  StyledWorkingHoursText,
} from '~pages/Info/styled.components';

const MIN_PHONE_NUMBER_CHARACTERS = 9;

export const CallBackSection = () => {
  const { localized } = useTranslation();
  const { formatPhoneNumberPayload } = usePhoneNumber();
  const { localizedError } = useTranslation();

  const { callUserBackMutation, callUserBackIsLoading } = useCallUserBack();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);
  const isDisabled =
    phoneNumber.length < MIN_PHONE_NUMBER_CHARACTERS || callUserBackIsLoading;

  const handleCallbackClick = async () => {
    try {
      const formattedPhoneNumber = formatPhoneNumberPayload(phoneNumber);

      await callUserBackMutation(formattedPhoneNumber).unwrap();
      setIsSuccess(true);
    } catch (error: unknown) {
      const errorCode = (error as ErrorData)?.data?.message?.[0];

      if (errorCode) {
        const errorMessage = localizedError(`phoneNumber.${errorCode}`);

        setError(errorMessage);
      }
    }
  };

  const handleInputChange = (value: string) => {
    if (error) {
      setError(null);
    }

    setPhoneNumber(value);
  };

  const renderInputWithButton = () => (
    <InputWithButton
      input={{
        customComponent: (
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            onChange={handleInputChange}
            isError={!!error}
          />
        ),
      }}
      button={{
        onClick: handleCallbackClick,
        text: localized('contactUsPage.callback'),
        isLoading: callUserBackIsLoading,
        isDisabled,
      }}
      isSuccess={isSuccess}
      successText={localized('contactUsPage.successText')}
      error={error}
    />
  );

  return (
    <StyledTopSectionWrapper>
      <Box flexCol alignCenter gap={2} css={{ maxWidth: '568px' }}>
        <StyledTitleText>{localized('contactUsPage.title')}</StyledTitleText>
        <StyledSupportText textTransform="uppercase">
          {localized('contactUsPage.support')}
        </StyledSupportText>
      </Box>
      <Box>{renderInputWithButton()}</Box>
      <StyledWorkingHoursText color="textSecondaryOne">
        {localized('contactUsPage.workingHours')}
      </StyledWorkingHoursText>
    </StyledTopSectionWrapper>
  );
};
