import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import {
  StyledSettingsPopoverContent,
  StyledSettingsPopoverItem,
} from '~components/atoms/SettingsDropdown/styled.components';
import { Text } from '~components/atoms/Typography';
import { ODDS } from '~constants/odds';
import { TIMEZONES } from '~constants/timezones';
import { useMedia } from '~hooks';
import { useTranslation } from '~hooks/useTranslation';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';

import { flagsMap, langOptions } from './SettingsDropdownPopover';

export const SettingsDropdownPopoverMobile = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  const {
    odds,
    timezone,
    onTimezoneChange,
    onOddsChange,
    onLanguageChange,
    language,
  } = useWebsiteSettings();

  const showLanguage = false;

  return (
    <>
      {!isMobileOrTablet && (
        <Text textTransform="uppercase" level="14-20">
          {localized('settings.label')}
        </Text>
      )}

      <StyledSettingsPopoverContent
        css={{ background: '$primaryTwo', margin: '16px 0 20px' }}
      >
        {showLanguage && (
          <StyledSettingsPopoverItem>
            <Text
              level="12-20"
              textTransform="uppercase"
              color="textSecondaryOne"
            >
              {localized('settings.language')}
            </Text>
            <SelectWithLabel
              value={language}
              setValue={onLanguageChange}
              options={langOptions}
              additionalSelectedComponents={flagsMap}
              dropdownWidth="100%"
            />
          </StyledSettingsPopoverItem>
        )}
        <StyledSettingsPopoverItem>
          <Text
            level="12-20"
            textTransform="uppercase"
            color="textSecondaryOne"
          >
            {localized('settings.timeZone')}
          </Text>

          <SelectWithLabel
            value={timezone}
            setValue={onTimezoneChange}
            options={TIMEZONES}
            style={{
              width: '100%',
              '& .ant-select-selector': {
                backgroundColor: '$secondaryTwo !important',
              },
            }}
          />
        </StyledSettingsPopoverItem>

        <StyledSettingsPopoverItem>
          <Text
            level="12-20"
            textTransform="uppercase"
            color="textSecondaryOne"
          >
            {localized('settings.oddsFormat')}
          </Text>
          <SelectWithLabel
            value={odds}
            setValue={onOddsChange}
            options={ODDS}
            dropdownWidth="100%"
            style={{
              '& .ant-select-selector': {
                backgroundColor: '$secondaryTwo !important',
              },
            }}
          />
        </StyledSettingsPopoverItem>
      </StyledSettingsPopoverContent>
    </>
  );
};
