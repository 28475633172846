import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useQueryParams } from '~hooks/useQueryParams';
import debounce from '~utils/debounce';
import { getPreparedParamsString } from '~utils/scrollPositionLocationUtils';

export const usePageScrollPosition = () => {
  const locationScrollPositionsRef = useRef<Record<string, number>>({});
  const search = useQueryParams();

  const resQueryString = useMemo(
    () => getPreparedParamsString(search),
    [search],
  );

  const handleScroll = useCallback(
    debounce(200, () => {
      const scrollTop = window.scrollY;

      if (scrollTop && resQueryString) {
        locationScrollPositionsRef.current[resQueryString] = scrollTop;
      }
    }),
    [resQueryString],
  );

  useEffect(() => {
    if (!resQueryString) return;

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, resQueryString]);

  // Restore scroll position on page load
  useEffect(() => {
    if (!resQueryString) return;

    const windowScrollPosSaved =
      locationScrollPositionsRef.current[resQueryString] || 0;

    window.scrollTo(0, windowScrollPosSaved);
  }, [resQueryString]);
};
