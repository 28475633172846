import React from 'react';

import { Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { PaddingWrapper } from '~components/molecules/GamesModalFilters/Filters';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

interface FiltersFooterProps {
  isClearDisabled: boolean;
  handleResetFilter: () => void;
  handleFilterGames: () => void;
  filteredGamesState: Games;
}

export const FiltersFooter = ({
  isClearDisabled,
  handleResetFilter,
  handleFilterGames,
  filteredGamesState,
}: FiltersFooterProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  return !isMobileOrTablet ? (
    <PaddingWrapper
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '$5',
      }}
    >
      <Separator
        verticalSpace={0}
        css={{
          mt: '20px',
          background: '$primaryTwo',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <Box
        fullWidth
        css={{
          display: 'flex',
          justifyContent: 'center',
          gap: '$2',
        }}
      >
        <Button
          variant="secondaryTwo"
          fullWidth
          size={'small'}
          onClick={handleResetFilter}
          disabled={isClearDisabled}
          css={{ maxWidth: '288px' }}
        >
          {localized('casino.clearAllFilters')}
        </Button>
        <Button
          fullWidth
          size={'small'}
          disabled={!filteredGamesState.length}
          onClick={handleFilterGames}
          css={{ maxWidth: '288px' }}
        >
          {`show ${filteredGamesState.length} games`}
        </Button>
      </Box>
    </PaddingWrapper>
  ) : (
    <Box css={{ backgroundColor: '$secondaryOne' }}>
      <Separator
        verticalSpace={0}
        css={{
          mt: '20px',
          background: '$primaryTwo',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '$2',
          p: '$2 50px',
          '@xs_sm': {
            p: '$4',
          },
        }}
      >
        <Button
          variant="secondary"
          fullWidth
          size={'small'}
          css={{
            fontWeight: '$medium',
            background: '$secondaryTwo',
          }}
          onClick={handleResetFilter}
          disabled={isClearDisabled}
        >
          {localized('casino.clearAllFilters')}
        </Button>
        <Button
          fullWidth
          size={'small'}
          disabled={!filteredGamesState.length}
          onClick={handleFilterGames}
        >
          show {filteredGamesState.length} games
        </Button>
      </Box>
    </Box>
  );
};
