import { memo } from 'react';

import { useAppSelector } from '~store';

export enum BANNER_GROUP_NAMES {
  RIGHT_TOP = 'right_top',
  RIGHT_BOTTOM = 'right_bottom',
  HEADER = 'header',
  JACKPOT_HEADER = 'jackpot_header',
  JACKPOT_LEFT_SIDEBAR = 'jackpot_left_sidebar',
  JACKPOT_RIGHT_SIDEBAR = 'jackpot_right_sidebar',
  CASINO_HEADER = 'header_casino',
  LIVE_CASINO_HEADER = 'header_live_casino',
  VIRTUAL_CASINO_HEADER = 'header_virtual',
}

export const bannerGroupNameWeb = [
  BANNER_GROUP_NAMES.RIGHT_BOTTOM,
  BANNER_GROUP_NAMES.RIGHT_TOP,
  BANNER_GROUP_NAMES.HEADER,
  BANNER_GROUP_NAMES.JACKPOT_LEFT_SIDEBAR,
  BANNER_GROUP_NAMES.JACKPOT_RIGHT_SIDEBAR,
];

export const bannerGroupNameMobile = [
  BANNER_GROUP_NAMES.HEADER,
  BANNER_GROUP_NAMES.JACKPOT_HEADER,
];

interface BannerListProps {
  names: string[];
  isVertical?: boolean;
}

export const BannerList = memo(({ names, isVertical }: BannerListProps) => {
  const { bannerData } = useAppSelector((state) => state.userUIState);

  console.log(bannerData, names, isVertical);

  return null;
  // return names.map((name) => {
  //   return (
  //     bannerData[name] && (
  //       <BannerItem
  //         group={bannerData[name]!}
  //         isVeritical={isVertical}
  //         key={name}
  //       />
  //     )
  //   );
  // });
});
