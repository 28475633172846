import React from 'react';
import { CellProps } from 'react-table';

import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { InfoPopover } from '~components/atoms/InfoPopover';
import { Text } from '~components/atoms/Typography';
import { WinboostPopover } from '~components/WinboostPopover';
import { BETSLIP_STATUS, BETSLIP_WIN_STATUSES } from '~constants/common';
import { useStakePayout, useTranslation } from '~hooks';
import { useAppSelector } from '~store';
import { oddFormatWithCurrency } from '~utils/numberUtils';

export const WinAmountCell = ({
  value,
  row,
}: CellProps<BetslipHistoryItem>) => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);

  const {
    stake,
    status,
    possibleWin,
    winBoostAmount,
    winBoostMultiplier,
    originalWinAmount,
    incomeTax,
    possibleIncomeTax,
    incomeTaxPercent,
    stakeTaxPercent,
    stakeTax,
    winAmount,
    stakeTaxPayer,
  } = row.original;

  const isOpenBet = status === BETSLIP_STATUS.OPEN;

  const { showPayoutInfo, payoutInfoItems } = useStakePayout({
    stake: stake + stakeTax, // in BE response, stake is the amount after taxes
    stakeAfterTax: stake,
    stakeTax,
    stakeTaxPercent,
    stakeTaxPayer,
    possibleWin,
    possibleIncomeTax: incomeTax || possibleIncomeTax,
    incomeTaxPercent,
    ...(!isOpenBet && { win: winAmount }),
  });

  const displayAmount = isOpenBet ? possibleWin : value;
  const isWinAmount = BETSLIP_WIN_STATUSES.includes(status);

  return (
    <Box flexRow gap={2} alignCenter>
      <Text
        color={isWinAmount ? 'accentPrimaryTwo' : 'textPrimaryOne'}
        level="12-20"
      >
        {oddFormatWithCurrency(displayAmount, currency)}
      </Text>
      {!!winBoostAmount && status !== BETSLIP_STATUS.CASH_OUT && (
        <WinboostPopover
          isBetHistory
          status={status}
          winBoostAmount={winBoostAmount}
          winBoostMultiplier={winBoostMultiplier}
          originalWinAmount={originalWinAmount}
        />
      )}
      {showPayoutInfo && (
        <InfoPopover
          title={`${localized('betslip.payoutPopover.info')}:`}
          itemsList={payoutInfoItems}
        />
      )}
    </Box>
  );
};
