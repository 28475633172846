import { memo, MouseEvent, useRef } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks';
import { GroupItem } from '~types/events';
import { capitalizeFirstLetter } from '~utils/stringUtils';

export const StyledContainer = styled(Box, {
  maxWidth: '100%',
  cursor: 'pointer',
  ml: '$6',
  '&:first-child': {
    ml: 0,
  },
  '&:last-child': {
    pr: '$2',
  },
});

interface MarketGroupsProps {
  groupsToShow: GroupItem[];
  selectedGroupId: string;
  setSelectedGroupId: (name: string) => void;
}

const MarketGroups = memo(
  ({
    groupsToShow,
    selectedGroupId,
    setSelectedGroupId,
  }: MarketGroupsProps) => {
    const { isMobileOrTablet } = useMedia();
    const notEmptyGroups =
      groupsToShow.length &&
      groupsToShow.filter(({ items }) => {
        return Object.keys(items || {})?.length;
      }).length;

    const scrollRef = useRef<HTMLDivElement>(null);

    const onGroupSelect = (event: MouseEvent, id: string) => {
      event.currentTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });

      setSelectedGroupId(id);
    };

    if (notEmptyGroups < 2) {
      return null;
    }

    return (
      <Box
        css={{
          borderRadius: '$8',
          backgroundColor: '$primaryTwo',
          margin: '$1 $1 0 $1',
          position: 'relative',
        }}
      >
        <ScrollArea orientation="horizontal" noScrollbar ref={scrollRef}>
          {/*<Box*/}
          {/*  css={{*/}
          {/*    position: 'absolute',*/}
          {/*    height: '100%',*/}
          {/*    width: `${NOT_VISIBLE_DIV_WIDTH}px`,*/}
          {/*    right: 0,*/}
          {/*    borderRadius: '0px $8 $8 0px',*/}
          {/*    background:*/}
          {/*      'linear-gradient(270deg, $primaryTwo 0%, rgba(44, 53, 64, 0.00) 100%)',*/}
          {/*    pointerEvents: 'auto',*/}
          {/*  }}*/}
          {/*  onClick={handleGradientDivClick}*/}
          {/*/>*/}
          <Box
            flexRow
            css={{
              borderRadius: '$8',
              p: '$2 $3',
              mx: '$1',
              '@xs_sm': {
                p: '$4 $3',
                overflowX: 'auto',
                overflowY: 'hidden',
              },
            }}
          >
            {groupsToShow.map((group, index) => {
              const { name, id, items = [] } = group;
              const isSelected = group.id === selectedGroupId;
              const count = Object.keys(items).length;

              if (count === 0) return;

              return (
                <StyledContainer
                  flexRow
                  key={index}
                  onClick={(e) => onGroupSelect(e, id)}
                  css={{
                    '&:last-child': {
                      paddingRight: '40px',
                    },
                  }}
                  className="group-container"
                >
                  <Text
                    noWrap
                    level={isMobileOrTablet ? '14-20' : '16-24'}
                    color={isSelected ? 'accentPrimaryTwo' : 'textPrimaryOne'}
                    css={{}}
                    data-group-id={id}
                  >
                    {capitalizeFirstLetter(name)}
                  </Text>
                  <Text
                    noWrap
                    level={isMobileOrTablet ? '14-20' : '16-24'}
                    color={isSelected ? 'accentPrimaryTwo' : 'textSecondaryOne'}
                    css={{ ml: '$1' }}
                  >
                    {`(${count})`}
                  </Text>
                </StyledContainer>
              );
            })}
          </Box>
        </ScrollArea>
      </Box>
    );
  },
);

export default MarketGroups;
