import { memo, MouseEvent, useEffect, useRef } from 'react';
import { styled } from 'stitches.config';

import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { EventNavigationLink } from '~components/atoms/EventNavigationLink';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { SPORT_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { FavoriteIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setSportTabActiveContent } from '~store/slices/mobileSlice';
import { Market } from '~types/events';
import { ellipsis } from '~utils/cssUtils';
import { getFormattedMarketsCount } from '~utils/numberUtils';

import { LeagueOddButtonGroup } from '../LeagueOddButtonGroup';

const StyledWrapper = styled(Box, {
  borderRadius: '$8',
  backgroundColor: '$secondaryTwo',
  p: '$3 $3 $2',
});

const StyledMarketCount = styled(Tag, {
  cursor: 'pointer',
  backgroundColor: '$textSecondaryOne',
  '&:hover': {
    backgroundColor: '$textPrimaryOne',
  },
});

interface LeagueEventContentMobileProps {
  time: string;
  eventId: string;
  isFirstEvent: boolean;
  isFirstDate: boolean;
  handleFavoriteClick: (isEventFavorite: boolean) => Promise<void>;
  eventData: SportEventItem;
  marketsCount?: number;
  homeTeamName?: string;
  awayTeamName?: string;
  selectedMarket?: Market;
}

export const LeagueEventContentMobile = memo(
  ({
    time,
    eventId,
    homeTeamName,
    awayTeamName,
    marketsCount,
    selectedMarket,
    eventData,
    isFirstEvent,
    isFirstDate,
    handleFavoriteClick,
  }: LeagueEventContentMobileProps) => {
    const dispatch = useAppDispatch();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const favoriteWrapperRef = useRef<HTMLDivElement>(null);
    const { isUserLoggedIn } = useAppSelector((state) => state.userState);
    const { favoritePrematchEvents } = useAppSelector(
      (state) => state.sportGroupsState,
    );
    const isEventFavorite = !!favoritePrematchEvents?.find((favoriteEvent) => {
      return favoriteEvent.id === eventId;
    });

    const handleMarketsClick = () => {
      dispatch(
        setSportTabActiveContent(SPORT_TAB_CONTENTS.PREMATCH_EVENT_MARKETS),
      );
    };

    const handleFavoriteWrapperClick = async (e: MouseEvent) => {
      e.stopPropagation();

      await handleFavoriteClick(isEventFavorite);

      if (favoriteWrapperRef.current) {
        favoriteWrapperRef.current.blur();
      }
    };

    useEffect(() => {
      if (isFirstEvent && isFirstDate && wrapperRef.current) {
        wrapperRef.current.scrollIntoView(false);
      }
    }, [isFirstEvent, eventId, isFirstDate, wrapperRef.current]);

    return (
      <StyledWrapper flexCol gap={3} ref={wrapperRef}>
        <Box flexRow justifyContentBetween alignEnd fullWidth gap={2}>
          <Box css={{ ...ellipsis }}>
            <EventNavigationLink eventId={eventId} onClick={handleMarketsClick}>
              <Box
                flexCol
                css={{
                  textDecoration: 'inherit',
                  color: '$textPrimaryOne',
                  '&:hover': {
                    color: '$accentPrimaryOne',
                  },
                }}
              >
                <Text level="12-20" css={{ color: 'unset' }} ellipsis>
                  {homeTeamName}
                </Text>
                <Text level="12-20" css={{ color: 'unset' }} ellipsis>
                  {awayTeamName}
                </Text>
              </Box>
            </EventNavigationLink>
          </Box>
          {selectedMarket && (
            <Box flexRow>
              <LeagueOddButtonGroup
                eventData={eventData}
                key={selectedMarket.id}
                eventId={eventId}
                market={selectedMarket}
                isFirstEvent={isFirstEvent}
              />
            </Box>
          )}
        </Box>
        <Box flexRow justifyContentBetween alignCenter>
          <Text color="textSecondaryOne" level="12-16">
            {time}
          </Text>
          <Box flexRow alignCenter gap={2}>
            {isUserLoggedIn && (
              <Box
                css={{
                  height: '$5',
                  color: isEventFavorite
                    ? '$textPrimaryOne'
                    : '$textSecondaryOne',
                  '&:focus': { color: '$textPrimaryOne' },
                }}
                onClick={handleFavoriteWrapperClick}
                ref={favoriteWrapperRef}
              >
                <FavoriteIcon width={14} height={14} />
              </Box>
            )}
            <Box
              css={{
                height: '$3',
                width: '1px',
                backgroundColor: '$secondaryOne',
              }}
            />
            <EventNavigationLink eventId={eventId} onClick={handleMarketsClick}>
              <StyledMarketCount
                text={getFormattedMarketsCount(marketsCount)}
                size="small"
                css={{
                  width: '32px',
                  height: '14px',
                  lineHeight: '$15',
                }}
              />
            </EventNavigationLink>
            <Box
              css={{
                height: '$3',
                width: '1px',
                backgroundColor: '$secondaryOne',
              }}
            />
          </Box>
        </Box>
      </StyledWrapper>
    );
  },
);
