import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { useBankSubmitButton } from '../../hooks/useBankSubmitButton';

export const BankSubmitButton = ({ disabled }: { disabled: boolean }) => {
  const { localized } = useTranslation();
  const { isDisabled, isLoading, handleBankChangeClick } =
    useBankSubmitButton();

  return (
    <Button
      fullWidth
      isLoading={isLoading}
      disabled={disabled || isDisabled}
      onClick={handleBankChangeClick}
      css={{
        p: '$2',
        marginTop: '$3',
        '@xs_sm': {
          height: '$7',
          mt: '$1',
        },
      }}
    >
      <Text isButton level="16-24" fontWeight="bold" textAlign="center">
        {localized('payments.continue')}
      </Text>
    </Button>
  );
};
