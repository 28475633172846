import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setPaymentMethodAmount } from '~store/slices/paymentsSlice';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const AmountAdjustmentButtons = () => {
  const dispatch = useAppDispatch();
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const {
    paymentSettings: {
      favoriteWithdrawAmounts: favoriteAmountsWithdraw,
      favoriteDepositAmounts: favoriteAmountsSettings,
    } = {
      favoriteWithdrawAmounts: [],
      favoriteDepositAmounts: [],
    },
  } = useAppSelector((state) => state.settings);
  const isDeposit = contentTab === USER_PAYMENT_TABS.DEPOSIT;

  const favotiteAmounts = isDeposit
    ? favoriteAmountsSettings || []
    : favoriteAmountsWithdraw || [];
  const { localized } = useTranslation();

  const handleButtonClick = (nextValue: number) => {
    dispatch(setPaymentMethodAmount(nextValue.toString()));
  };

  return (
    <Box
      justifyContentBetween
      fullWidth
      flexRow
      css={{
        gap: '$2',
        marginTop: '$2',
      }}
    >
      {[...favotiteAmounts, 0].map((item) => (
        <Button
          fullWidth
          key={`option-${item}`}
          variant="secondary"
          css={{
            p: '$1 $4',
            boxShadow: 'none',
            '&:hover': {
              color: '$hoverPrimary',
            },
            '@xs_sm': {
              height: '$7',
            },
          }}
          onClick={() => handleButtonClick(item)}
        >
          <Text level="sm-4" textAlign="center">
            {item === 0
              ? localized('payments.clear')
              : commaSeparatedNumber(item, true)}
          </Text>
        </Button>
      ))}
    </Box>
  );
};
