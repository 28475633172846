import { Bonus, BONUS_STATUS } from '~api/bonus/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { BonusHistoryColumns } from '../../constants';
import { GAP_HISTORY_COLUMNS } from '../BonusHistory';

import { useBonusHistoryItem } from './useBonusHistoryItem';

interface BonusHistoryItemProps {
  bonus: Bonus;
  historyColumns: BonusHistoryColumns;
}

export const BonusHistoryItem = ({
  bonus,
  historyColumns,
}: BonusHistoryItemProps) => {
  const {
    expiresDate,
    givenDate,
    maxWinName,
    remainingAmount,
    status,
    statusName,
    title,
    totalAmount,
    won,
    currency,
    localized,
  } = useBonusHistoryItem({ bonus });
  const [
    bonusNameColumn,
    sectionColumn,
    remainingAmountColumn,
    wonAmountColumn,
    statusColumn,
  ] = historyColumns;
  const givenDateText = `${localized('bonuses.given', {
    postfix: ':',
  })} ${givenDate}`;
  const expiresDateText = `${localized(
    status === BONUS_STATUS.ACTIVE ? 'bonuses.expiresIn' : 'bonuses.expired',
    {
      postfix: ':',
    },
  )} ${expiresDate}`;

  return (
    <Box
      flexRow
      alignCenter
      gap={GAP_HISTORY_COLUMNS}
      css={{
        borderRadius: '$6',
        backgroundColor: '$secondaryTwo',
        p: '$2 0 $2 $3',
      }}
    >
      <Box
        flexCol
        css={{
          width: bonusNameColumn?.width,
          minWidth: bonusNameColumn?.width,
        }}
      >
        <Text level="13-20" fontWeight="medium">
          {title}
        </Text>
        <Text level="12-20">{givenDateText}</Text>
      </Box>
      <Box flexCol css={{ minWidth: sectionColumn?.width }}>
        <Text level="12-20" textTransform="capitalize">
          {localized('bonuses.sport')}
        </Text>
      </Box>
      <Box flexRow css={{ minWidth: remainingAmountColumn?.width }}>
        <Text level="13-20" fontWeight="medium" css={{ pr: '$1' }}>
          {commaSeparatedNumber(totalAmount - remainingAmount)}
        </Text>
        <Text level="12-20">{`/ ${commaSeparatedNumber(totalAmount)} ${
          CURRENCY_SYMBOLS[currency]
        }`}</Text>
      </Box>
      <Box flexRow css={{ minWidth: wonAmountColumn?.width }}>
        <Text level="13-20" fontWeight="medium" css={{ pr: '$1' }}>
          {won}
        </Text>
        <Text level="12-20">{maxWinName}</Text>
      </Box>
      <Box flexCol css={{ width: statusColumn?.width }}>
        <Text
          level="13-20"
          fontWeight="medium"
          color={
            status === BONUS_STATUS.ACTIVE ? 'greenPrimary' : 'textPrimaryOne'
          }
        >
          {statusName}
        </Text>
        <Text level="12-20">{expiresDateText}</Text>
      </Box>
    </Box>
  );
};
