import React, { memo, useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { NumberInput } from '~components/atoms/NumberInput';
import { Text } from '~components/atoms/Typography';
import { betslipTextStyles } from '~components/molecules/Betslip/components/BetslipStakes';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { useDebounce, useMedia, useTranslation } from '~hooks';
import { useAppSelector } from '~store';

interface BetslipInputFieldProps {
  isMultipleBet?: boolean;
  mainAmount?: string;
  bonusAmount?: string;
  handleSingleInputChange: (value: string) => void;
  handleMainChange: (value: string) => void;
  handleBonusChange: (value: string) => void;
}

export const BetslipInputField = memo(
  ({
    isMultipleBet = false,
    mainAmount = '',
    bonusAmount = '',
    handleSingleInputChange,
    handleMainChange,
    handleBonusChange,
  }: BetslipInputFieldProps) => {
    const { localized } = useTranslation();
    const { isMobileOrTablet } = useMedia();
    const { separateBonusBalance } = useAppSelector((state) => state.settings);
    const { stakeType } = useAppSelector((state) => state.betslip);

    const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
    const isPrimaryColor = !isMobileOrTablet && isMultipleBet;

    const [main, setMain] = useState(mainAmount);
    const [bonus, setBonus] = useState(bonusAmount);

    useEffect(() => {
      if (mainAmount !== main) {
        setMain(mainAmount);
      }

      if (bonusAmount !== bonus) {
        setBonus(bonusAmount);
      }
    }, [mainAmount, bonusAmount]);

    const debouncedOnChange = useDebounce(
      (value: string, callback: (value: string) => void) => {
        callback(value);
      },
      300,
    );

    const onMainChange = (value: string) => {
      setMain(value);
      debouncedOnChange(value, handleMainChange);
    };

    const onBonusChange = (value: string) => {
      setBonus(value);
      debouncedOnChange(value, handleBonusChange);
    };

    return (
      <Box flexRow alignCenter fullWidth>
        {separateBonusBalance && !isSystemBet ? (
          <Box fullWidth flexRow gap={3} css={{ minWidth: 0 }}>
            <Box fullWidth flexRow alignCenter gap={1} css={{ minWidth: 0 }}>
              <Text css={betslipTextStyles}>
                {localized('betslip.cash') + ':'}
              </Text>
              <NumberInput
                fullWidth
                withoutActions
                onChange={onMainChange}
                value={main}
                inputColor={isPrimaryColor ? '$primaryTwo' : '$secondaryTwo'}
              />
            </Box>

            <Box fullWidth flexRow alignCenter gap={1} css={{ minWidth: 0 }}>
              <Text css={betslipTextStyles}>
                {localized('betslip.freeBet') + ':'}
              </Text>
              <NumberInput
                isPrimaryColor={isPrimaryColor}
                fullWidth
                withoutActions
                onChange={onBonusChange}
                value={bonus}
                inputColor={isPrimaryColor ? '$primaryTwo' : '$secondaryTwo'}
              />
            </Box>
          </Box>
        ) : (
          <NumberInput
            isPrimaryColor={isPrimaryColor}
            fullWidth
            onChange={(value) => handleSingleInputChange(value)}
            value={mainAmount}
            inputColor={isPrimaryColor ? '$primaryTwo' : '$secondaryTwo'}
          />
        )}
      </Box>
    );
  },
);
