import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, Slider } from 'antd';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks';
import { useAppSelector } from '~store';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

interface NumberRangeInputProps {
  value: number[];
  numberRangeValidValues: [minValidValue: number, maxValidValue: number];
  minValuePlaceholder?: string;
  maxValuePlaceholder?: string;
  onChange?: (props: number[]) => void;
}

export const NumberRangeInput = ({
  value,
  numberRangeValidValues,
  minValuePlaceholder = 'Min.',
  maxValuePlaceholder = 'Max.',
  onChange,
}: NumberRangeInputProps) => {
  const [min, max] = value || [0, 0];
  const [minValidValue = 0, maxValidValue] = numberRangeValidValues;
  const { isMobileOrTablet } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);

  const clearButton = (
    <Button
      variant="secondary"
      size="small"
      disabled={areTwoObjectsEqual(value, numberRangeValidValues)}
      css={{
        height: '34px',
        background: '$secondaryTwo',
        borderRadius: '$4',
        color: '$textButtons',
      }}
      onClick={() => onChange && onChange(numberRangeValidValues)}
    >
      <Text level="12-20">Clear</Text>
    </Button>
  );

  return isMobileOrTablet ? (
    <Box
      flexCol
      css={{
        background: '$primaryOne',
        p: '6px',
        borderRadius: '$8',
      }}
    >
      <Box
        css={{
          p: '$2',
          background: '$secondaryOne',
          borderRadius: '$6',
          border: '1px solid $secondaryTwo',
        }}
      >
        <Box
          flexCol
          gap={2}
          css={{
            background: '$secondaryOne',
            borderRadius: '$6',
          }}
        >
          <Box>
            <Box
              flexRow
              alignCenter
              css={{
                border: '1px solid $secondaryTwo',
                borderRadius: '$4',
                '& .ant-slider': {
                  width: '100%',
                },
                p: '0 6px',
              }}
            >
              <Slider
                range
                onChange={onChange}
                min={minValidValue}
                max={maxValidValue}
                value={[min as number, max as number]}
              />
            </Box>
          </Box>
          <Box flexRow justifyContentBetween gap={1}>
            <Box
              flexRow
              alignCenter
              gap={1}
              css={{
                border: '1px solid $secondaryTwo',
                borderRadius: '$4',
                p: '0 6px',
                color: '$textPrimaryOne',
              }}
            >
              <Box
                flexRow
                alignCenter
                css={{
                  height: '28px',
                }}
              >
                <InputNumber
                  variant="borderless"
                  min={minValidValue ?? 0}
                  max={max ?? undefined}
                  placeholder={minValuePlaceholder}
                  value={min}
                  onChange={(currentMin) =>
                    onChange && onChange([currentMin as number, max as number])
                  }
                />
              </Box>
              <SwapRightOutlined />
              <Box
                flexRow
                alignCenter
                css={{
                  height: '28px',
                }}
              >
                <InputNumber
                  variant="borderless"
                  min={minValidValue ?? 0}
                  max={maxValidValue ?? undefined}
                  placeholder={maxValuePlaceholder}
                  value={max}
                  onChange={(currentMax) =>
                    onChange && onChange([min as number, currentMax as number])
                  }
                />
                <Text level="14-20">{CURRENCY_SYMBOLS[currency]}</Text>
              </Box>
            </Box>
            {clearButton}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      flexCol
      css={{
        background: '$primaryOne',
        p: '$4',
        borderRadius: '$10',
      }}
    >
      <Box
        flexRow
        justifyContentBetween
        css={{
          background: '$secondaryOne',
          borderRadius: '$8',
          border: '1px solid $secondaryTwo',
          p: '$2',
        }}
      >
        <Box flexRow alignCenter gap={2}>
          <Box
            flexRow
            alignCenter
            css={{
              border: '1px solid $secondaryTwo',
              borderRadius: '$6',
              p: '0 $3',
            }}
          >
            <InputNumber
              variant="borderless"
              min={minValidValue ?? 0}
              max={max ?? undefined}
              placeholder={minValuePlaceholder}
              value={min}
              onChange={(currentMin) =>
                onChange && onChange([currentMin as number, max as number])
              }
            />
            <Text level="14-20">{CURRENCY_SYMBOLS[currency]}</Text>
          </Box>
          <Slider
            range
            onChange={onChange}
            value={[min as number, max as number]}
            min={minValidValue}
            max={maxValidValue}
          />
          <Box
            flexRow
            alignCenter
            css={{
              border: '1px solid $secondaryTwo',
              borderRadius: '$6',
              p: '0 $3',
            }}
          >
            <InputNumber
              variant="borderless"
              min={min || minValidValue || 1}
              max={maxValidValue ?? undefined}
              placeholder={maxValuePlaceholder}
              value={max}
              onChange={(currentMax) =>
                onChange && onChange([min as number, currentMax as number])
              }
            />
            <Text level="14-20">{CURRENCY_SYMBOLS[currency]}</Text>
          </Box>
        </Box>
        {clearButton}
      </Box>
    </Box>
  );
};
