import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { useSearch } from '~components/molecules/PrematchMenu/hooks/useSearch';
import {
  StyledSearchIconWrapper,
  StyledSearchWrapper,
  timeSelectorStyles,
} from '~components/molecules/PrematchMenu/PrematchMenu/styled.components';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { SEARCH_TERM_MIN_LENGTH } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import {
  AllSportIcon,
  FilterIcon,
  GameIcon,
  RedCrossIcon,
  SearchIcon,
  UpcomingIcon,
} from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import {
  setActiveSportTabGroup,
  setIsFavoriteSelected,
} from '~store/slices/mobileSlice';
import { setDateToFilter } from '~store/slices/prematchMenuSlice';
import {
  selectActiveSportTabGroup,
  selectIsFavoriteSelected,
} from '~store/slices/selectors/mobileState';
import { SVGElement } from '~types/general';
import { TIME_RANGES } from '~types/timeRanges';
import { getLocalizedTimeRanges } from '~utils/getLocalizedTimeRanges';

interface SearchBarProps {
  onSearchedItemsChange: (items: SportEventItems) => void;
  onShowSearchResultsChange: (show: boolean) => void;
}

export const SearchBar = memo(
  ({ onSearchedItemsChange, onShowSearchResultsChange }: SearchBarProps) => {
    const dispatch = useAppDispatch();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const activeTabGroup = useAppSelector(selectActiveSportTabGroup);
    const isFavoriteSelected = useAppSelector(selectIsFavoriteSelected);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const { localized } = useTranslation();
    const [timeRange, setTimeRange] = useState(TIME_RANGES.ALL as unknown);

    const sportTabGroups = useMemo(
      () => [
        {
          name: SPORT_TAB_GROUPS.TOP,
          icon: GameIcon,
          label: localized('sportTab.top'),
          id: 0,
        },
        {
          name: SPORT_TAB_GROUPS.UPCOMMING,
          icon: UpcomingIcon,
          label: localized('sportTab.upcoming'),
          id: 2,
        },
        {
          name: SPORT_TAB_GROUPS.ALL,
          icon: AllSportIcon,
          label: localized('sportTab.all'),
          id: 1,
        },
      ],
      [],
    );

    const {
      searchRef,
      isSearchActive,
      searchValue,
      showSearchResults,
      searchedItems,
      onToggleSearch,
      handleSearchValueChange,
      handleClearSearch,
    } = useSearch();

    const handleTimeRangeChange = useCallback((value: unknown) => {
      setTimeRange(value);
      dispatch(
        setDateToFilter(
          value === TIME_RANGES.ALL ? null : (value as TIME_RANGES),
        ),
      );
    }, []);

    const handleDropdownVisibleChange = (open: boolean) => {
      setDropdownOpen(open);
    };

    useEffect(() => {
      if (isSearchActive) {
        searchInputRef.current?.focus();
      }
    }, [searchInputRef, isSearchActive]);

    useEffect(() => {
      onSearchedItemsChange(searchedItems);
    }, [searchedItems]);

    useEffect(() => {
      onShowSearchResultsChange(showSearchResults);
    }, [showSearchResults]);

    const searchBarPaddings =
      isSearchActive || searchValue.length ? '0 $4' : '0 $4 0 $1';

    return (
      <Box
        flexRow
        alignCenter
        fullWidth
        css={{
          backgroundColor: '$primaryOne',
          height: '44px',
          p: searchBarPaddings,
          mb: searchValue.length >= SEARCH_TERM_MIN_LENGTH ? 0 : '$1',
        }}
      >
        {isSearchActive || searchValue.length ? (
          <StyledSearchWrapper ref={searchRef}>
            <SearchIcon width={16} height={16} />
            <Input
              value={searchValue}
              ref={searchInputRef}
              onChange={handleSearchValueChange}
            />
            <RedCrossIcon onClick={handleClearSearch} />
          </StyledSearchWrapper>
        ) : (
          <Box
            flexRow
            fullWidth
            alignCenter
            gap={2}
            justifyContentBetween
            css={{ backgroundColor: '$primaryOne', padding: '0' }}
          >
            <Box
              css={{
                width: `calc(100% - ${
                  (activeTabGroup === SPORT_TAB_GROUPS.ALL ? 72 : 32) + 8
                }px)`,
              }}
            >
              <TouchSlider noGradient type="sportGroup">
                {sportTabGroups.map(({ id, name, label, icon }) => {
                  const isActiveTab =
                    activeTabGroup === name && !isFavoriteSelected;

                  return (
                    <SportSliderItem
                      key={id}
                      label={label}
                      icon={icon as SVGElement}
                      className={isActiveTab ? 'active' : ''}
                      onClick={() => {
                        if (isActiveTab) return;

                        if (name === SPORT_TAB_GROUPS.ALL) {
                          dispatch(setBreadcrumbsLabel(null));
                        }

                        dispatch(setIsFavoriteSelected(false));
                        dispatch(setActiveSportTabGroup(name));
                      }}
                    />
                  );
                })}
              </TouchSlider>
            </Box>
            <Box flexRow alignCenter gap={2}>
              {activeTabGroup === SPORT_TAB_GROUPS.ALL &&
              !isFavoriteSelected ? (
                <SelectWithLabel
                  value={timeRange}
                  setValue={handleTimeRangeChange}
                  options={getLocalizedTimeRanges(localized)}
                  handleDropdownVisibleChange={handleDropdownVisibleChange}
                  dropdownWidth="118px"
                  dropdownStyles={{
                    '.ant-select-item': {
                      backgroundColor: '$primaryTwo !important',
                    },
                  }}
                  icon={<FilterIcon />}
                  style={{
                    ...timeSelectorStyles,
                    '.ant-select-selector': {
                      boxShadow: isDropdownOpen
                        ? '$elementActiveBorder !important'
                        : 'none !important',
                    },
                    '.ant-select-dropdown': {
                      border: '1px solid $primaryTwo !important',
                    },
                    svg: {
                      color:
                        timeRange !== TIME_RANGES.ALL
                          ? '$textPrimaryOne'
                          : '$textSecondaryOne',
                    },
                  }}
                />
              ) : null}
              <StyledSearchIconWrapper
                iconButton
                type={'button'}
                onClick={onToggleSearch}
              >
                <SearchIcon width={16} height={16} />
              </StyledSearchIconWrapper>
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);
