import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { useCardSubmitButton } from '../../hooks/useCardSubmitButton';

export const CardSubmitButton = () => {
  const { localized } = useTranslation();
  const { isDisabled, isLoading, handleCardCharge } = useCardSubmitButton();

  return (
    <Button
      fullWidth
      isLoading={isLoading}
      disabled={isDisabled}
      onClick={handleCardCharge}
      css={{
        p: '$2',
        marginTop: '$1',
        '@xs_sm': {
          height: '$7',
          mt: '$1',
        },
      }}
    >
      <Text level="16-24" fontWeight="bold" textAlign="center">
        {localized('payments.continue')}
      </Text>
    </Button>
  );
};
