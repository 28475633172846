export const betprazer = {
  colors: {
    primaryOne: '#130314',
    primaryTwo: '#1C1F33',
    secondaryOne: '#161621',
    secondaryTwo: '#2D2D40',
    textButtons: '#FAFAFA',
    textPrimaryOne: '#FAFAFA',
    textSecondaryOne: '#BAC1CC',
    textPrimaryTwo: '#130314',
    accentPrimaryOne: '#1EA30A',
    accentSecondaryOne: '#0A3804',
    accentPrimaryTwo: '#FFD600',
    accentSecondaryTwo: '#382F00',
    pressedOne: '#136606',
    redPrimary: '#FF216B',
    redSecondary: '#380718',
    greenPrimary: '#1EA30A',
    greenSecondary: '#0A3804',
    yellowPrimary: '#FFD600',
    yellowSecondary: '#382F00',
    bluePrimary: '#1F60FF',
    orangePrimary: '#F07300',
    hoverPrimary: '#287A1B',
    hoverSecondary: '#130314',

    // COLORS WITH OPACITY
    primaryOneOpacity: 'rgba(19, 3, 20, 0.7)',
    greenPrimaryOpacity: 'rgba(30, 163, 10, 0.2)',
    yellowPrimaryOpacity: 'rgba(255, 214, 0, 0.2)',
    primaryTwoOpacity: 'rgba(28, 31, 51, 0.7)',
    footerBackgroundOpacity: 'rgba(22, 22, 33, 0.80)',
    blackOpacity0: 'rgba(7, 33, 56, 0.0)',
  },
  shadows: {
    elementHoverBorder: 'inset 0 0 0 1px #287A1B',
    elementActiveBorder: 'inset 0 0 0 1px #136606',
  },
};
