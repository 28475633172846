import { useEffect, useRef, useState } from 'react';

import { useLazyGetFavoriteEventInplay } from '~api/sportEvent/sportEventQueries';
import { InPlayMenuSports } from '~api/sportEvent/types';
import { SIGNAL_R_SOCKET_MESSAGES } from '~constants/signalR';
import {
  decreaseInplayCount,
  increaseInplayCount,
} from '~features/sportsMenu/sportsMenuSlice';
import { useLiveMenuHubConnection } from '~hooks';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import useSocket from '~socket-service';
import { SOCKET_TYPE } from '~socket-service/constants';
import { useAppDispatch, useAppSelector } from '~store';
import { replaceEvents } from '~store/slices/eventsSlice';
import { setFavoriteInplayEvents } from '~store/slices/liveGroupsSlice';
import {
  addLiveMenuEvent,
  removeLiveMenuEvent,
  resetAddedLeagueId,
  resetAddedSportId,
  resetUpdatedSportMarket,
  setIsLiveMenuLoaded,
  setIsLiveMenuLoading,
  setLiveMenuSports,
  setLoadingSportId,
} from '~store/slices/liveMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { SportEvent } from '~types/events';
import { SPORT_GROUPS } from '~types/sportGroups';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

import {
  getMainMarketId,
  reduceEventsByLeague,
  reduceEventsBySport,
  reduceOpenedSportEventsBySport,
} from '../utils/eventsParsing';
import { resolveRedirectPath } from '../utils/helpers';

export const useLiveMenu = () => {
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const { updateQueryParams } = useRouterQuery();
  const search = useQueryParams();
  const { group } = search;

  const { lazyGetFavoriteEventInplayQuery } = useLazyGetFavoriteEventInplay();

  const { isPrimaryDataLoaded } = useEventsLoadingSocket();

  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const language = useAppSelector(selectLanguage);

  const {
    liveMenuSports: liveMenu,
    addedLeagueId,
    addedSportId,
    mainMarketsSelected,
    updatedSportMarket,
    openedCountryLeagues,
    isLiveMenuLoaded,
    isLiveMenuLoading,
  } = useAppSelector((state) => state.liveMenu);

  const liveMenuRef = useRef(liveMenu);

  useEffect(() => {
    liveMenuRef.current = liveMenu;
  }, [liveMenu]);

  const [isLoadedOpenedCountries, setIsLoadedOpenedCountries] =
    useState(isLiveMenuLoaded);

  const {
    listening: listeningSignalR,
    sendMessage: sendSRMessage,
    isSocketReady: isSRSocketReady,
  } = useSocket(SOCKET_TYPE.SIGNALR);
  const {
    listening: listeningWebSocket,
    sendMessage: sendWSMessage,
    isSocketReady: isWSSocketReady,
  } = useSocket(SOCKET_TYPE.WEB);

  useEffect(() => {
    if (isLiveMenuLoaded) return;

    if (isPrimaryDataLoaded && isWSSocketReady) {
      sendWSMessage(ACTION_TYPE.GET_IN_PLAY, {
        Language: language,
      });
    }
  }, [isLiveMenuLoaded, isPrimaryDataLoaded, isWSSocketReady]);

  useEffect(() => {
    if (!isWSSocketReady || !isSRSocketReady) return;

    const stopListeningWebSocket = listeningWebSocket({
      [ACTION_TYPE.GET_IN_PLAY]: (data) => {
        const parsedSports = data as InPlayMenuSports;
        const isAnyEmptySport = parsedSports.some(
          ({ countries }) => !countries.length,
        );

        if (isAnyEmptySport) return;

        dispatch(setLiveMenuSports(parsedSports));
        dispatch(setIsLiveMenuLoaded(true));
      },
      [ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID]: (data) => {
        const inPlayEventsByMarketData = data;

        const liveMenuSportsData = liveMenuRef.current;

        if (liveMenuSportsData) {
          const resultInPlayMenu = liveMenuSportsData.map(
            ({ countries, ...rest1 }) => {
              return {
                ...rest1,
                countries: countries.map(({ leagues, ...rest2 }) => {
                  return {
                    ...rest2,
                    leagues: leagues.map(({ events, ...rest3 }) => {
                      return {
                        ...rest3,
                        events: events.map((event) => {
                          const updatedEvent = inPlayEventsByMarketData.find(
                            (evt: SportEvent) => evt.id === event.id,
                          );

                          if (updatedEvent) {
                            return {
                              ...event,
                              ...updatedEvent,
                            };
                          }

                          return event;
                        }),
                      };
                    }),
                  };
                }),
              };
            },
          );

          dispatch(setLiveMenuSports(resultInPlayMenu));
        }

        dispatch(replaceEvents(inPlayEventsByMarketData));
        setTimeout(() => {
          dispatch(resetAddedLeagueId());
          dispatch(resetAddedSportId());
          dispatch(setLoadingSportId(null));
          dispatch(setIsLiveMenuLoading(false));
        }, 100);
      },
    });

    const stopListeningSignalR = listeningSignalR({
      [SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_ADD_EVENT]: (response) => {
        dispatch(addLiveMenuEvent(response));
        dispatch(increaseInplayCount());
      },
      [SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_REMOVE_EVENT]: (response) => {
        const { id: eventId, ...rest } = response;

        dispatch(removeLiveMenuEvent({ eventId, ...rest }));
        dispatch(decreaseInplayCount());
      },
    });

    return () => {
      stopListeningSignalR();
      stopListeningWebSocket();
      sendSRMessage(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_FIXTURE_MESSAGE);
    };
  }, [isWSSocketReady, isSRSocketReady]);

  useLiveMenuHubConnection();

  useEffect(() => {
    const loadFavoriteInplayEvents = async () => {
      try {
        const favoriteInplayEvents =
          await lazyGetFavoriteEventInplayQuery().unwrap();

        dispatch(setFavoriteInplayEvents(favoriteInplayEvents));
      } catch (e) {
        console.error('Failed to load favorite inplay events: ', e);
      }
    };

    if (!isUserLoggedIn) return;
    loadFavoriteInplayEvents();
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (isMobileOrTablet && addedSportId) {
      dispatch(resetAddedSportId());

      return;
    }

    if (addedSportId === null) return;

    dispatch(setIsLiveMenuLoading(true));

    const events = reduceEventsBySport({
      liveMenu,
      openedCountryLeagues,
      sportId: parseInt(addedSportId),
    });

    if (!events.length) return;
    const numberSportId = parseInt(addedSportId);
    const marketId =
      mainMarketsSelected[numberSportId] ||
      getMainMarketId({ mainMarkets, sportId: numberSportId });

    sendWSMessage(ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID, {
      Ids: events,
      MarketId: marketId,
      Language: language,
    });
  }, [
    addedSportId,
    liveMenu,
    mainMarkets,
    openedCountryLeagues,
    isMobileOrTablet,
    mainMarketsSelected,
    isPrimaryDataLoaded,
    language,
  ]);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (isMobileOrTablet && addedLeagueId) {
      dispatch(resetAddedLeagueId());

      return;
    }

    if (addedLeagueId === null) return;
    dispatch(setIsLiveMenuLoading(true));
    const { sportId, events } = reduceEventsByLeague(liveMenu, addedLeagueId);

    if (!events.length) return;
    const marketId =
      mainMarketsSelected[sportId] || getMainMarketId({ mainMarkets, sportId });

    sendWSMessage(ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID, {
      Ids: events,
      MarketId: marketId,
      Language: language,
    });
  }, [
    addedLeagueId,
    liveMenu,
    mainMarkets,
    mainMarketsSelected,
    isPrimaryDataLoaded,
    language,
  ]);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (updatedSportMarket) {
      const { sportId, marketId } = updatedSportMarket;

      dispatch(resetUpdatedSportMarket());

      const eventIds = reduceOpenedSportEventsBySport({
        liveMenu,
        sportId,
        openedCountryLeagues,
      });

      if (!eventIds.length) return;

      sendWSMessage(ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID, {
        Ids: eventIds,
        MarketId: marketId,
        Language: language,
      });
    }
  }, [
    liveMenu,
    mainMarkets,
    updatedSportMarket,
    openedCountryLeagues,
    search,
    isPrimaryDataLoaded,
    language,
  ]);

  useEffect(() => {
    if (
      group !== SPORT_GROUPS.TOP_EVENTS &&
      liveMenu.length &&
      !isMobileOrTablet
    ) {
      updateQueryParams(resolveRedirectPath(liveMenu, search), true);
    }
  }, [liveMenu, isMobileOrTablet, group, search]);

  useEffect(() => {
    if (openedCountryLeagues.length && !isLoadedOpenedCountries) {
      setTimeout(() => {
        setIsLoadedOpenedCountries(true);
      }, 300);
    }
  }, [openedCountryLeagues]);

  return {
    liveSports: liveMenu,
    isLoadedOpenedCountries: isLoadedOpenedCountries,
    isLoaded: isLiveMenuLoaded,
    isLoading: isLiveMenuLoading,
  };
};
