import { useMemo } from 'react';

import {
  PREMATCH_SELECTION_HEIGHT,
  SELECTIONS_GAP,
} from '~/constants/mainPage';
import { MainMarket } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks/useMedia';
import { Market } from '~types/events';

import { useEventMarket } from './hooks/useEventMarket';
import { getLiveSelectionHeight } from './helpers';
import { MarketSelection } from './MarketSelection';

interface EventMarketProps {
  market: Market | undefined;
  mainMarket: MainMarket | undefined;
  eventId: string;
  isLive?: boolean;
  eventData: SportEventItem;
}

export const EventMarket = ({
  market,
  mainMarket,
  eventId,
  isLive,
  eventData,
}: EventMarketProps) => {
  const { isLaptop } = useMedia();
  const { selections, marketId, isTotal, totalMarketName } = useEventMarket({
    mainMarket,
    market,
  });

  const items = useMemo(() => {
    return selections.map((selection, index) => {
      return (
        <MarketSelection
          eventData={eventData}
          key={index}
          index={index}
          isLast={index === selections.length - 1}
          selection={selection}
          isTotalLabel={isTotal && index === 0}
          totalLabel={totalMarketName}
          eventId={eventId}
          marketId={marketId}
          isLive={isLive}
        />
      );
    });
  }, [selections, isTotal, totalMarketName, eventId, marketId, isLive]);

  return (
    <Box
      fullHeight
      flexRow
      css={{
        minHeight: `${
          isLive ? getLiveSelectionHeight(isLaptop) : PREMATCH_SELECTION_HEIGHT
        }px`,
        gap: `${SELECTIONS_GAP}px`,
      }}
    >
      {items}
    </Box>
  );
};
