import { MouseEvent } from 'react';
import { styled } from 'stitches.config';

import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { EventNavigationLink } from '~components/atoms/EventNavigationLink';
import { LiveLabel } from '~components/atoms/LiveLabel';
import { Text } from '~components/atoms/Typography';
import { useLiveEventDetails } from '~components/molecules/LiveMenu/components/liveEventDetails';
import { useMedia } from '~hooks/useMedia';
import { useTwoTeamScore } from '~hooks/useTwoTeamScore';
import { FavoriteIcon } from '~icons';
import { useAppSelector } from '~store';
import { ellipsis } from '~utils/cssUtils';
import { getParsedExtraData } from '~utils/sportHelpers';
import { splitStringOnVs } from '~utils/stringUtils';

import { useInplayFavoriteEvents } from '../InplayEvents/hooks/useInplayFavoriteEvents';

const StyledFavoriteIcon = styled(FavoriteIcon, {
  cursor: 'pointer',
  '&:hover': {
    color: '$textPrimaryOne',
  },
});

export interface MainEventDataProps {
  eventData: SportEventItem | undefined;
}

export const MainEventLiveData = ({ eventData }: MainEventDataProps) => {
  const { isLaptop } = useMedia();
  const {
    marketsCount,
    startDate,
    extraData,
    name,
    id = '',
    sportId,
    countryId,
    leagueId,
  } = eventData || {};
  const [homeTeamName, awayTeamName] = splitStringOnVs(name || '');
  const parsedExtraData = getParsedExtraData(extraData);
  const { homeTeamScore, awayTeamScore } =
    useTwoTeamScore(parsedExtraData) || {};
  const { formattedMarketsCount, formattedStartDate, formattedMatchStatus } =
    useLiveEventDetails(marketsCount, startDate, parsedExtraData);
  const { handleFavoriteInplayEventClick, isEventFavorite } =
    useInplayFavoriteEvents(eventData);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const handleFavoriteIconClick = async (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    await handleFavoriteInplayEventClick();
  };

  return (
    <Box
      fullWidth
      flexRow
      justifyContentBetween
      css={{
        p: isLaptop ? '$1' : '$2',
      }}
    >
      <Box flexCol justifyContentStart alignStart>
        <EventNavigationLink
          eventId={id}
          sportId={sportId}
          countryId={countryId}
          leagueId={leagueId}
        >
          <Box
            flexCol
            css={{
              flexShrink: 0,
              textDecoration: 'inherit',
              ...ellipsis,
              '&:hover > p': {
                color: '$accentPrimaryOne',
              },
            }}
          >
            <Text
              level={isLaptop ? '12-20' : '14-24'}
              ellipsis
              css={{
                pr: isLaptop ? '$1' : '$3',
              }}
            >
              {homeTeamName}
            </Text>
            <Text
              level={isLaptop ? '12-20' : '14-24'}
              ellipsis
              css={{
                pr: isLaptop ? '$1' : '$3',
              }}
            >
              {awayTeamName}
            </Text>
          </Box>
        </EventNavigationLink>
        <Box flexRow gap={2} alignCenter css={{ mt: isLaptop ? '$1' : '$2' }}>
          <LiveLabel />
          <Text level={isLaptop ? '12-20' : '14-24'} color="textSecondaryOne">
            {formattedStartDate}, {formattedMatchStatus}
          </Text>
        </Box>
      </Box>
      <Box flexCol justifyContentStart>
        <Text level={isLaptop ? '12-20' : '14-24'} textAlign="right">
          {homeTeamScore}
        </Text>
        <Text level={isLaptop ? '12-20' : '14-24'} textAlign="right">
          {awayTeamScore}
        </Text>
        <Box
          flexRow
          gap={2}
          alignCenter
          css={{
            mt: '$1',
          }}
        >
          {isUserLoggedIn && (
            <Box
              flexRow
              css={{
                color: isEventFavorite
                  ? '$textPrimaryOne'
                  : '$textSecondaryOne',
              }}
            >
              <StyledFavoriteIcon onClick={handleFavoriteIconClick} />
            </Box>
          )}
          <EventNavigationLink
            eventId={id}
            sportId={sportId}
            countryId={countryId}
            leagueId={leagueId}
          >
            <Text
              level={isLaptop ? '12-20' : '14-24'}
              color="textSecondaryOne"
              underline
            >
              {formattedMarketsCount}
            </Text>
          </EventNavigationLink>
        </Box>
      </Box>
    </Box>
  );
};
