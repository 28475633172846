import React from 'react';

import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import {
  statusTranslationsMap,
  TRANSACTION_STATUS_CODE_COLORS,
  TransactionHistoryCellProps,
} from '~types/transactions';

export const StatusCell = ({
  row: {
    original: { status },
  },
}: TransactionHistoryCellProps) => {
  const { localized } = useTranslation();

  return (
    <Text color={TRANSACTION_STATUS_CODE_COLORS[status]} level="12-20">
      {localized(statusTranslationsMap[status])}
    </Text>
  );
};
