import { Fragment } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { USER_PROFILE_NAVIGATION_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { useBreadcrumbEmptyCallback } from '~hooks/useBreadcrumbEmptyCallback';
import useIsInIframe from '~hooks/useIsInIframe';
import { useTranslation } from '~hooks/useTranslation';
import { ArrowLeftIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { BreadcrumbItem } from '~types/breadcrumb';

interface BreadcrumbProps {
  maxItemsDisplayed?: number;
  separator?: string;
  onBackPress?: () => void;
  customStyles?: Record<string, string>;
  propsTrail?: BreadcrumbItem[];
}

export const Breadcrumb = ({
  maxItemsDisplayed = 2,
  separator = '/',
  onBackPress,
  customStyles,
  propsTrail,
}: BreadcrumbProps) => {
  const dispatch = useAppDispatch();
  const { handleEmptyBreadcrumbClick } = useBreadcrumbEmptyCallback();
  const storeBreadcrumbs = useAppSelector((state) => state.breadcrumb.trail);
  const fullBreadcrumbs = propsTrail?.length ? propsTrail : storeBreadcrumbs;
  const { mobileUserProfileDialogActiveContent } = useAppSelector(
    (state) => state.mobileState,
  );
  const breadcrumbs = fullBreadcrumbs.slice(-maxItemsDisplayed);
  const lastElementIndex = breadcrumbs.length - 1;
  const isInIframe = useIsInIframe();
  const { localized } = useTranslation();

  const isIframeBonus =
    isInIframe &&
    mobileUserProfileDialogActiveContent ===
      USER_PROFILE_NAVIGATION_TABS.BONUSES;

  const handleBreadcrumbClick = (breadcrumb: BreadcrumbItem, index: number) => {
    const { forceCallback, onClickCallback = () => {} } = breadcrumb;

    if (forceCallback) {
      onClickCallback();

      return;
    }

    if (index < lastElementIndex) {
      const newTrail = fullBreadcrumbs.slice(
        0,
        fullBreadcrumbs.length - (lastElementIndex - index),
      );

      dispatch(setBreadcrumbTrail(newTrail));

      if (onClickCallback) {
        onClickCallback();
      } else {
        handleEmptyBreadcrumbClick();
      }
    }

    onBackPress && onBackPress();
  };

  if (breadcrumbs.length === 0 || isIframeBonus) {
    return null;
  }

  return (
    <Box
      flexRow
      alignCenter
      gap={2}
      css={{
        color: '$textSecondaryOne',
        backgroundColor: '$secondaryOne',
        backdropFilter: 'blur(4px)',
        borderBottom: '1px solid $primaryTwo',
        p: '$4',
        ...customStyles,
      }}
    >
      {isInIframe ? (
        <Text
          as="span"
          color="textSecondaryOne"
          level="14-20"
          textTransform="uppercase"
          ellipsis
        >
          {localized('userProfile.menuTitles.history')}
        </Text>
      ) : (
        <>
          <ArrowLeftIcon
            onClick={() => handleBreadcrumbClick(breadcrumbs[0]!, 0)}
          />
          {breadcrumbs.map((breadcrumb, index) => (
            <Fragment key={breadcrumb.label}>
              <Text
                as="span"
                color={
                  index === lastElementIndex
                    ? 'textPrimaryOne'
                    : 'textSecondaryOne'
                }
                level="14-20"
                textTransform="uppercase"
                onClick={() => handleBreadcrumbClick(breadcrumb, index)}
                ellipsis
              >
                {breadcrumb.label}
              </Text>
              {index < lastElementIndex && (
                <Text as="span" level="14-20">
                  {separator}
                </Text>
              )}
            </Fragment>
          ))}
        </>
      )}
    </Box>
  );
};
