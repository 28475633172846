import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { PureIconButton } from '~components/atoms/PureIconButton';

export const StyledNumberInputContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '$secondaryTwo',
  borderRadius: '$10',
  '@xs_sm': {
    height: '32px',
    width: '236px',
    flexGrow: 1,
  },

  variants: {
    isPrimaryColor: {
      true: {
        backgroundColor: '$primaryTwo',
      },
    },
    fullWidth: {
      true: {
        '@xs_sm': {
          width: '100%',
        },
      },
    },
  },
});

export const StyledPureIconButton = styled(PureIconButton, {
  width: '$8',
  height: '$8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    content: '',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '1px',
    height: '$4',
    backgroundColor: '$primaryTwo',
  },
  '&:hover > svg': {
    color: '$accentPrimaryTwo',
  },
  '@xs_sm': {
    height: '32px',
  },

  variants: {
    isPrimaryColor: {
      true: {
        '&:before': {
          backgroundColor: '$primaryOne',
        },
      },
    },
    left: {
      true: {
        paddingRight: '$4',
        marginLeft: '$3',
        '&:before': {
          right: 0,
        },
      },
    },
    right: {
      true: {
        paddingLeft: '$4',
        marginRight: '$3',
        '&:before': {
          left: 0,
        },
      },
    },
  },
});
