import { memo, MouseEvent, RefObject, useRef } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { FavoriteIcon } from '~icons';
import { useAppSelector } from '~store';

const StyledFavoriteIcon = styled(FavoriteIcon, {
  cursor: 'pointer',
  '@md_lg_xl': {
    '&:hover': {
      color: '$textPrimaryOne',
    },
  },
  '@xs_sm': {
    '&:focus': {
      color: '$textPrimaryOne',
    },
  },
});

const StyledMarketCount = styled(Tag, {
  cursor: 'pointer',
  backgroundColor: '$textSecondaryOne',
  '&:hover': {
    backgroundColor: '$textPrimaryOne',
  },
});

interface MobileViewProps {
  isUserLoggedIn: boolean;
  isEventFavorite: boolean;
  favoriteWrapperRef: RefObject<HTMLDivElement>;
  handleFavoriteIconClick: (event: MouseEvent) => void;
  startDate: string;
  matchStatus: string;
  details?: string | null;
  marketsCount: string;
}

// Memoized subcomponent for mobile view
const MobileView = memo(
  ({
    isUserLoggedIn,
    isEventFavorite,
    favoriteWrapperRef,
    handleFavoriteIconClick,
    startDate,
    matchStatus,
    details,
    marketsCount,
  }: MobileViewProps) => (
    <>
      <Separator
        shrinkOut={3}
        verticalSpace={1}
        css={{ backgroundColor: '$primaryTwo' }}
      />
      <Box flexRow justifyContentBetween alignCenter>
        <Box flexCol justifyCenter alignStart css={{ gap: '2px' }}>
          <Text level="12-20" color="textSecondaryOne">
            {startDate + ', '}
            {matchStatus}
          </Text>
          {details && (
            <Text level="12-20" color="textSecondaryOne">
              {details}
            </Text>
          )}
        </Box>
        <Box flexRow alignCenter gap={2} css={{ height: '$5' }}>
          {isUserLoggedIn && (
            <Box
              flexRow
              css={{
                color: isEventFavorite
                  ? '$textPrimaryOne'
                  : '$textSecondaryOne',
              }}
              ref={favoriteWrapperRef}
            >
              <StyledFavoriteIcon
                width={14}
                height={14}
                onClick={handleFavoriteIconClick}
              />
            </Box>
          )}
          <Box
            css={{
              width: '1px',
              height: '14px',
              backgroundColor: '$primaryTwo',
            }}
          />
          <StyledMarketCount
            text={marketsCount}
            size="small"
            css={{
              width: '32px',
              height: '14px',
              lineHeight: '$15',
            }}
          />
        </Box>
      </Box>
    </>
  ),
);

interface DesktopViewProps {
  isUserLoggedIn: boolean;
  isEventFavorite: boolean;
  handleFavoriteIconClick: (event: MouseEvent) => void;
  marketsCount: string;
  startDate: string;
}

// Memoized subcomponent for desktop view
const DesktopView = memo(
  ({
    isUserLoggedIn,
    isEventFavorite,
    handleFavoriteIconClick,
    marketsCount,
    startDate,
  }: DesktopViewProps) => (
    <Box flexCol justifyContentEnd alignEnd css={{ gap: '2px' }}>
      <Box flexRow alignCenter gap={1} css={{ height: '$5' }}>
        {isUserLoggedIn && (
          <Box
            flexRow
            css={{
              color: isEventFavorite ? '$textPrimaryOne' : '$textSecondaryOne',
            }}
          >
            <StyledFavoriteIcon onClick={handleFavoriteIconClick} />
          </Box>
        )}
        <StyledMarketCount
          text={marketsCount}
          size="small"
          css={{
            width: '32px',
            height: '14px',
            lineHeight: '$15',
          }}
        />
      </Box>
      <Text level="12-20" noWrap color="textSecondaryOne">
        {startDate}
      </Text>
    </Box>
  ),
);

interface LiveEventDetailsProps {
  isMobile: boolean;
  marketsCount: string;
  startDate: string | null;
  matchStatus: string;
  details?: string | null;
  handleFavoriteIconClick: (event: MouseEvent) => void;
  isEventFavorite: boolean;
}

export const LiveEventDetails = memo(
  ({
    isMobile,
    marketsCount,
    startDate,
    matchStatus,
    details = null,
    isEventFavorite,
    handleFavoriteIconClick,
  }: LiveEventDetailsProps) => {
    const { isUserLoggedIn } = useAppSelector((state) => state.userState);

    const favoriteWrapperRef = useRef<HTMLDivElement>(null);

    return (
      <Box fullWidth>
        {isMobile ? (
          <MobileView
            isUserLoggedIn={isUserLoggedIn}
            isEventFavorite={isEventFavorite}
            favoriteWrapperRef={favoriteWrapperRef}
            handleFavoriteIconClick={handleFavoriteIconClick}
            startDate={startDate as string}
            matchStatus={matchStatus}
            details={details}
            marketsCount={marketsCount}
          />
        ) : (
          <DesktopView
            isUserLoggedIn={isUserLoggedIn}
            isEventFavorite={isEventFavorite}
            handleFavoriteIconClick={handleFavoriteIconClick}
            marketsCount={marketsCount}
            startDate={startDate as string}
          />
        )}
      </Box>
    );
  },
);
