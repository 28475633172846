import * as AccordionUI from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { ChevronDownIcon } from '~icons';

export const StyledTrigger = styled(AccordionUI.Trigger, {
  all: 'unset',
  position: 'relative',
  fontFamily: 'inherit',
  flex: 1,
  px: '$3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$md',
  lineHeight: '$44',
  color: '$textPrimaryOne',
  backgroundColor: '$primaryTwo',
  cursor: 'pointer',
  borderRadius: '$8',
  '&[data-state=open]': {
    borderBottomRightRadius: 'unset',
    borderBottomLeftRadius: 'unset',
    borderTopRightRadius: '$8',
    borderTopLeftRadius: '$8',
  },
  '&[data-state=open] *, &:hover *': {
    color: '$textPrimaryOne',
    'svg path': {
      fill: '$textPrimaryOne', // Set the fill color of the SVG path to white
    },
  },
  '&[data-state=open] > .chevron': {
    transform: 'rotate(180deg)',
  },
  variants: {
    mobileMenu: {
      true: {
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        borderRadius: 0,
        p: '$4 $8',
        '& .icon': {
          position: 'absolute',
          left: '$3',
          color: '$textSecondaryOne',
        },
        '& .chevron': {
          position: 'absolute',
          right: '$3',
          color: '$textSecondaryOne',
        },
      },
    },
    openDataState: {
      true: {
        color: '$accentPrimaryTwo',
        '& .icon': {
          position: 'absolute',
          left: '$3',
          color: '$textSecondaryOne',
        },
        '& .chevron': {
          position: 'absolute',
          right: '$3',
          color: '$textSecondaryOne',
        },
        '&[data-state=open]': {
          '& .chevron': {
            transform: 'rotate(180deg)',
          },
          '& > *': {
            color: '$accentPrimaryTwo',
            fontWeight: '$medium',
          },
          '& .icon': {
            color: '$accentPrimaryTwo',
          },
          '& svg path': {
            color: '$accentPrimaryTwo',
          },
        },
      },
    },
    mobileEventMenu: {
      true: {
        justifyContent: 'flex-start',
        fontSize: '$14',
        lineHeight: '$24',
        p: '$2 $4',
        '& .icon': {
          position: 'absolute',
          left: '$3',
          color: '$textSecondaryOne',
        },
        '& .chevron': {
          position: 'absolute',
          right: '$3',
          color: '$textSecondaryOne',
        },
        '&[data-state=open]': {
          '& .chevron': {
            transform: 'rotate(180deg)',
          },
          '& .icon': {
            color: '$accentPrimaryTwo',
          },
        },
      },
    },
    dark: {
      true: {
        backgroundColor: '$primaryOne',
      },
    },
    primaryTwo: {
      true: {
        backgroundColor: '$primaryTwo',
      },
    },
    isTransparentBackground: {
      true: {
        backgroundColor: 'transparent',
        borderRadius: '0 !important',
      },
    },
    isAlwaysRoundCorners: {
      true: {
        borderRadius: '$8 !important',
      },
    },
    noPadding: {
      true: {
        p: 0,
      },
    },
  },
});

export const StyledHeader = styled(AccordionUI.Header, {
  all: 'unset',
  display: 'flex',
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
});

export const StyledContent = styled(AccordionUI.Content, {
  color: '$secondaryTwo',
  mt: '1px',
  backgroundColor: '$primaryTwo',
  '& > .contentWrapper': {
    p: '$3',
    '@xs_sm': {
      p: '$2',
    },
  },
  variants: {
    mobileMenu: {
      true: {
        backgroundColor: 'transparent',
        m: 0,
      },
    },
    mobileMenuSeparated: {
      true: {
        backgroundColor: 'transparent',
        '& > .contentWrapper': {
          pl: 0,
          '@xs_sm': {
            p: 0,
            ml: '$8',
          },
        },
      },
    },
  },
});

export const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
});

export const StyledAccordionItem = styled(AccordionUI.Item, {
  overflow: 'hidden',
  position: 'relative',
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
  borderRadius: '$8',
  variants: {
    mobileMenu: {
      true: {
        borderRadius: '0 !important',
        backgroundColor: 'transparent',
        '& .contentWrapper': {
          p: '0 0 0 $8',
        },
      },
    },
    mobileMenuNoPadding: {
      true: {
        borderRadius: '0 !important',
        backgroundColor: 'transparent',
        p: 0,
      },
    },
  },
});

export const StyledAccordionRoot = styled(AccordionUI.Root, {
  width: '100%',
  variants: {
    mobileMenu: {
      true: {
        borderRadius: 0,
        backgroundColor: '$primaryTwo',
        '&:first-child': {
          borderTopLeftRadius: '$8',
          borderTopRightRadius: '$8',
        },
        '&:last-child': {
          borderBottomLeftRadius: '$8',
          borderBottomRightRadius: '$8',
        },
        '& .trigger[data-state="open"]': {
          borderBottom: '1px solid $secondaryOne',
        },
        '&:not(:last-child)': {
          '& .contentWrapper': {
            borderBottom: '1px solid $secondaryOne',
          },
          '& .trigger': {
            borderBottom: '1px solid $secondaryOne',
          },
        },
      },
    },
  },
});
