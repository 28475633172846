import type * as Stitches from '@stitches/react';
import { createStitches, createTheme } from '@stitches/react';

import { antStylesOverwriteCss } from '~styles/antStylesOverwriteCss';
import { baseStylesOverwriteCss } from '~styles/baseStylesOverwriteCss';

import * as themeColors from './src/styles/themes';

const stitches = createStitches(themeColors.defaultThemeConfig);

type ThemeKeys = keyof typeof themeColors;

const partnerTheme = import.meta.env.VITE_PARTNER_NAME as ThemeKeys;

export const appTheme = createTheme(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,import/namespace
  (themeColors?.[partnerTheme] || themeColors.defaultThemeConfig) as any,
);

const { styled, getCssText, globalCss, theme, config } = stitches;

// stitches types
export type StitchesConfig = typeof config;

export type Colors = typeof colors;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VariantProps<T extends { [key: string]: any; [key: symbol]: any }> =
  Stitches.VariantProps<T>;

export type CSS = Stitches.CSS<StitchesConfig>;

export type COLOR_KEYS = keyof typeof theme.colors;

const baseStylesOverwrite = globalCss(baseStylesOverwriteCss);
const antStylesOverwrite = globalCss(antStylesOverwriteCss);

const injectGlobalStyles = () => {
  baseStylesOverwrite();
  antStylesOverwrite();
};

const { fontSizes, fontWeights, colors, space, radii, lineHeights } = theme;

export {
  colors,
  fontSizes,
  fontWeights,
  getCssText,
  globalCss,
  injectGlobalStyles,
  lineHeights,
  radii,
  space,
  styled,
  theme,
};
