import React, { useEffect, useLayoutEffect } from 'react';
import { Providers } from 'src/components/molecules/Providers';

import { BackButton } from '~components/atoms/BackButton';
import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import { Filters } from '~components/molecules/GamesModalFilters/Filters';
import { Search } from '~components/molecules/GamesModalFilters/Search';
import { ProviderGames } from '~components/molecules/ProviderGames';
import { PROVIDERS_MAP } from '~constants/providers';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import {
  selectGamesState,
  setProvidersFilters,
  setSelectedProviderId,
} from '~store/slices/gamesSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { FILTER_TYPE } from '~types/games';

export const GamesModalFilters = () => {
  const dispatch = useAppDispatch();
  const { filterType, selectedProviderId } = useAppSelector(selectGamesState);

  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();

  useLayoutEffect(() => {
    const breadcrumbTrail = selectedProviderId
      ? [
          {
            label: localized('buttons.back'),
            onClickCallback: () => {
              dispatch(setSelectedProviderId(null));
            },
          },
          {
            label: PROVIDERS_MAP[selectedProviderId]?.name,
            icon: PROVIDERS_MAP[selectedProviderId]?.icon,
          },
        ]
      : [
          {
            label: localized('buttons.back'),
            onClickCallback: () => {
              dispatch(closeDialog());
            },
          },
          {
            label: localized(`casino.${filterType}Title`),
          },
        ];

    dispatch(setBreadcrumbTrail(breadcrumbTrail));
  }, [filterType, selectedProviderId]);

  // Clear filters when opening the modal
  useEffect(() => {
    dispatch(setProvidersFilters({}));
    dispatch(setSelectedProviderId(null));
  }, []);

  const filtersContentMap: Record<FILTER_TYPE, JSX.Element | null> = {
    [FILTER_TYPE.PROVIDERS]: <Providers />,
    [FILTER_TYPE.SEARCH]: <Search />,
    [FILTER_TYPE.FILTERS]: <Filters />,
  };

  // If a provider is selected, show the provider games
  const content = selectedProviderId ? (
    <ProviderGames />
  ) : (
    filtersContentMap[filterType as FILTER_TYPE]
  );

  const handleBackButtonPress = () => {
    dispatch(setSelectedProviderId(null));
  };

  return isMobileOrTablet ? (
    <DialogMobileContent isContentState={false}>
      <Box
        flexCol
        css={{
          overflowX: 'hidden',
          mb: '100px',
        }}
      >
        <Breadcrumb />
        <ScrollArea>{content}</ScrollArea>
      </Box>
    </DialogMobileContent>
  ) : (
    <Box
      fullWidth
      fullHeight
      css={{
        backgroundColor: '$secondaryOne',
        width: '1280px',
        height: '720px',
        '@md': {
          width: '918px',
          height: '792px',
        },
      }}
    >
      <Box fullWidth fullHeight flexCol>
        {selectedProviderId ? (
          <Box
            flexRow
            justifyContentStart
            css={{
              p: '1rem 20px 0',
              '@xl': {
                p: '1rem 24px 0',
              },
            }}
          >
            <BackButton
              onBackButtonPress={handleBackButtonPress}
              iconPath={PROVIDERS_MAP[selectedProviderId]?.iconPath}
              providerName={PROVIDERS_MAP[selectedProviderId]?.name}
            />
          </Box>
        ) : (
          <Box
            flexRow
            justifyContentStart
            css={{
              backgroundColor: '$secondaryOne',
              p: '1rem 36px 0',
              '@md': {
                p: '1rem 24px 0',
              },
            }}
          >
            <Text level="16-28" fontWeight="medium" textTransform="uppercase">
              {localized(`casino.${filterType}Title`)}
            </Text>
          </Box>
        )}
        {content}
      </Box>
    </Box>
  );
};
