import { styled } from '@stitches/react';

import { Box } from '~components/atoms/Box';
import { SvgIconComponent } from '~components/atoms/SvgIconComponent';

interface ProviderIconProps {
  iconPath?: string;
  active?: boolean;
  providerName?: string;
}

const ProviderIconWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$2',
  width: '$12',
  height: '$7',
  backgroundColor: '$secondaryOne',
  borderRadius: '$6',

  variants: {
    active: {
      true: {
        border: '1px solid $accentSecondaryOne',
      },
    },
  },
});

export const ProviderIcon = ({
  active,
  iconPath,
  providerName,
}: ProviderIconProps) => {
  return (
    <ProviderIconWrapper active={active}>
      <SvgIconComponent
        iconPath={iconPath}
        alt={providerName}
        styles={{ width: '100%' }}
      />
    </ProviderIconWrapper>
  );
};
