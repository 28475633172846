import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import {
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/HeaderComponent/Header/useHeaderMenu';
import { useTranslation } from '~hooks/useTranslation';
import {
  CasinoIcon,
  InplayIcon,
  LiveCasinoIcon,
  MenuIcon,
  VirtualSportsIcon,
} from '~icons/menu';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { SVGElement } from '~types/general';

import {
  MenuTabItem,
  MenuTabRoot,
  StyledMobileTabContainer,
  StyledMobileTabItem,
  StyledMobileTabItemCentral,
  StyledMobileTabItemIcon,
  StyledMobileTabItemText,
  StyledMobileTabRoundDecoration,
} from './styled.components';

interface MobileMenuItems {
  value: MOBILE_MENU;
  label: string;
  icon: SVGElement;
  inactive?: boolean;
}

export enum MOBILE_MENU {
  LIVE = 'liveSport',
  LIVE_CASINO = 'live',
  VIRTUAL = 'virtual',
  CASINO = 'casino',
  MENU = 'menu',
}

export const MobileTabCasino = () => {
  const { menu } = useHeaderMenu();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { localized } = useTranslation();

  const handleTabChange = (value: string) => {
    if (!value) return;
    window.scrollTo(0, 0);

    if (value === MOBILE_MENU.LIVE) {
      const { url } = menu.find(
        ({ icon }) => icon === MENU_ICON_TYPES.Sports,
      ) || { url: '' };

      if (url && url.length > 0) {
        window.location.href = url;
      }

      return;
    }

    if (value === MOBILE_MENU.CASINO) {
      navigate('/casino');

      return;
    }

    if (value === MOBILE_MENU.LIVE_CASINO) {
      navigate('/live');

      return;
    }

    if (value === MOBILE_MENU.VIRTUAL) {
      navigate('/virtual');

      return;
    }

    if (value === MOBILE_MENU.MENU) {
      dispatch(openDialog(DIALOGS.MENU));

      return;
    }

    return;
  };

  const mobileMenuItemsMemo: MobileMenuItems[] = useMemo(
    () => [
      {
        value: MOBILE_MENU.LIVE,
        label: localized('mobile.tabMenu.live'),
        icon: InplayIcon,
      },
      {
        value: MOBILE_MENU.CASINO,
        label: localized('mobile.tabMenu.casino'),
        icon: CasinoIcon,
      },
      {
        value: MOBILE_MENU.LIVE_CASINO,
        label: localized('mobile.tabMenu.liveCasino'),
        icon: LiveCasinoIcon,
      },
      {
        value: MOBILE_MENU.VIRTUAL,
        label: localized('mobile.tabMenu.virtual'),
        icon: VirtualSportsIcon,
        inactive: true,
      },
      {
        value: MOBILE_MENU.MENU,
        label: localized('mobile.tabMenu.menu'),
        icon: MenuIcon,
        inactive: true,
      },
    ],
    [],
  );

  const getTabsValue = (): string => {
    const pathname = window.location.pathname;

    if (pathname.includes('live')) {
      return MOBILE_MENU.LIVE_CASINO;
    }

    if (pathname.includes('casino') || pathname === '/') {
      return MOBILE_MENU.CASINO;
    }

    if (pathname.includes('virtual')) {
      return MOBILE_MENU.VIRTUAL;
    }

    return '';
  };

  return (
    <StyledMobileTabContainer>
      <MenuTabRoot
        onValueChange={handleTabChange}
        value={getTabsValue() as string}
      >
        {mobileMenuItemsMemo.map(({ value, label, icon: Icon }) => (
          <MenuTabItem key={value} value={value}>
            {value === MOBILE_MENU.LIVE_CASINO ? (
              <StyledMobileTabItemCentral>
                <StyledMobileTabItemIcon>
                  <Icon width={21} height={21} />
                </StyledMobileTabItemIcon>
                <StyledMobileTabItemText>{label}</StyledMobileTabItemText>
              </StyledMobileTabItemCentral>
            ) : (
              <StyledMobileTabItem>
                <StyledMobileTabItemIcon>
                  <Icon width={21} height={21} />
                </StyledMobileTabItemIcon>
                <StyledMobileTabItemText>{label}</StyledMobileTabItemText>
              </StyledMobileTabItem>
            )}
          </MenuTabItem>
        ))}
      </MenuTabRoot>
      <StyledMobileTabRoundDecoration />
    </StyledMobileTabContainer>
  );
};
