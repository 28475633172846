export const otbet = {
  colors: {
    primaryOne: '#000103',
    primaryTwo: '#1C232E',
    secondaryOne: '#14161C',
    secondaryTwo: '#2B2D36',
    textButtons: '#000103',
    textPrimaryOne: '#EBEBEB',
    textSecondaryOne: '#ADADAD',
    textPrimaryTwo: '#000103',
    accentPrimaryOne: '#FFB100',
    accentSecondaryOne: '#382700',
    accentPrimaryTwo: '#FFB100',
    accentSecondaryTwo: '#382700',
    pressedOne: '#996B00',
    redPrimary: '#F23005',
    redSecondary: '#380B01',
    greenPrimary: '#04BF20',
    greenSecondary: '#013809',
    yellowPrimary: '#FFDB00',
    yellowSecondary: '#383100',
    bluePrimary: '#1F60FF',
    orangePrimary: '#F07300',
    hoverPrimary: '#F5B92F',
    hoverSecondary: '#000103',

    // COLORS WITH OPACITY
    primaryOneOpacity: 'rgba(0, 1, 3, 0.7)',
    greenPrimaryOpacity: 'rgba(4, 191, 32, 0.2)',
    yellowPrimaryOpacity: 'rgba(255, 219, 0, 0.2)',
    primaryTwoOpacity: 'rgba(28, 35, 46, 0.7)',
    footerBackgroundOpacity: 'rgba(20, 22, 28, 0.80)',
    blackOpacity0: 'rgba(7, 33, 56, 0.0)',
  },
  shadows: {
    elementHoverBorder: 'inset 0 0 0 1px #F5B92F',
    elementActiveBorder: 'inset 0 0 0 1px #996B00',
  },
};
