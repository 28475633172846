import React, { useEffect, useState } from 'react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { NumberCodeFieldInput } from '~components/atoms/NumberCodeFieldInput';
import { Separator } from '~components/atoms/Separator';
import { ErrorMessage, Text } from '~components/atoms/Typography';
import { INPUT_CODE_LENGTH } from '~constants/auth';
import { useCountdownTimer } from '~hooks/useCountdownTimer';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { COOKIES_NAMES, getCookie, setCookie } from '~utils/cookies';

import {
  RESET_PASSWORD_FORM_FIELDS,
  ResetPasswordFormInputs,
} from '../../types';

import { ResetPasswordTabList } from './ResetPasswordTabList';

interface VerifyNumberTabProps {
  phoneNumberInput: UseFormRegisterReturn<RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD>;
  resetPasswordCodeIsSuccess: boolean;
  resetPasswordIsLoading: boolean;
  onNextTab: () => void;
  onReset: () => Promise<void>;
  verificationCodeExpiration?: string;
  onCodeChange: (code: string) => void;
}

export const VerifyNumberTab = ({
  verificationCodeExpiration,
  resetPasswordCodeIsSuccess,
  resetPasswordIsLoading,
  onNextTab,
  onReset,
  onCodeChange,
}: VerifyNumberTabProps) => {
  const { isMobile } = useMedia();
  const { setValue, register, formState, clearErrors } =
    useFormContext<ResetPasswordFormInputs>();
  const { errors } = formState;
  const [code, setCode] = useState('');
  const [getCodeIsLoading, setGetCodeIsLoading] = useState(false);
  const { localized } = useTranslation();
  const { timeRemaining, formatTime, isTimerAvailable, resetTimer } =
    useCountdownTimer(verificationCodeExpiration!);
  const isGetAgainButtonDisabled = isTimerAvailable || getCodeIsLoading;

  const updateNumberCode = (input: string): void => {
    setCode(input);
    onCodeChange(input);
    setValue(RESET_PASSWORD_FORM_FIELDS.NUMBER_CODE_FIELD, input);
  };

  const handleVerifyPhoneNewCode = async () => {
    if (!getCookie(COOKIES_NAMES.VERIFICATION_CODE_EXPIRATION)) {
      setGetCodeIsLoading(true);
      await onReset();
      setGetCodeIsLoading(false);
    }
  };

  useEffect(() => {
    if (code.length === INPUT_CODE_LENGTH && !errors.numberCodeField) {
      onNextTab();
    }
  }, [code]);

  useEffect(() => {
    if (resetPasswordCodeIsSuccess && verificationCodeExpiration) {
      resetTimer(verificationCodeExpiration!);
      setCookie(
        COOKIES_NAMES.VERIFICATION_CODE_EXPIRATION,
        verificationCodeExpiration,
        {
          expires: new Date(verificationCodeExpiration),
        },
      );
    }
  }, [resetPasswordCodeIsSuccess, verificationCodeExpiration]);

  return (
    <>
      <Text
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
        css={{
          fontSize: '$xl',
          lineHeight: '$44',
          mb: '$2',
        }}
      >
        {localized('resetPassword.checkPhone')}
      </Text>
      <Text
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
        css={{
          mb: '28px',
          fontSize: '17px',
          lineHeight: '$24',
        }}
      >
        {localized('resetPassword.verifyCodeSent')}
      </Text>
      <ResetPasswordTabList />
      <Box css={{ mb: '$4' }}>
        <NumberCodeFieldInput
          code={code}
          setCode={updateNumberCode}
          labelText={localized('resetPassword.fields.numberCode.label')}
          error={!!errors.numberCodeField}
          clearError={() => {
            clearErrors(RESET_PASSWORD_FORM_FIELDS.NUMBER_CODE_FIELD);
          }}
          {...register(RESET_PASSWORD_FORM_FIELDS.NUMBER_CODE_FIELD, {
            validate: {
              isInvalidCode: (value) => value.length === 6,
            },
          })}
        />
      </Box>
      <Text textAlign="center" level="sm-3" css={{ mb: '$1' }}>
        {localized('resetPassword.dontGetCode')}
      </Text>
      <Text textAlign="center" level="xxs-4" css={{ mb: '$4' }}>
        {localized('resetPassword.checkPhoneOrGetAgain')}
      </Text>
      <Box
        css={{
          mb: '$3',
          position: 'relative',
        }}
      >
        {errors.phoneNumberField && (
          <Box css={{ position: 'absolute', top: '-$4', width: '100%' }}>
            <ErrorMessage
              type="centeredError"
              message={errors.phoneNumberField.message!}
            />
          </Box>
        )}
      </Box>
      <Button
        size="medium"
        fullWidth
        onClick={handleVerifyPhoneNewCode}
        disabled={isGetAgainButtonDisabled}
        isLoading={resetPasswordIsLoading}
        css={{
          opacity: '1 !important',
          position: 'relative',
          backgroundColor: isGetAgainButtonDisabled
            ? '$secondaryTwo'
            : '$accentPrimaryOne',
        }}
      >
        <Text
          level={'16-20'}
          color={isGetAgainButtonDisabled ? 'secondaryOne' : 'textPrimaryOne'}
        >
          {localized('buttons.getAgain')}
        </Text>

        <Text
          color="yellowPrimary"
          textTransform="none"
          level="12-20"
          as="span"
          css={{
            position: 'absolute',
            right: '12px',
            top: '8px',
          }}
        >
          {isTimerAvailable
            ? localized('resetPassword.availableInN', {
                minutes: formatTime(timeRemaining),
              })
            : ''}
        </Text>
      </Button>
      <Separator
        verticalSpace={isMobile ? 3 : 6}
        shrinkOut={isMobile ? 4 : 7}
      />
      <Button fullWidth onClick={onNextTab}>
        {localized('buttons.next')}
      </Button>
    </>
  );
};
