import React from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setMainTab } from '~store/slices/personalDetailsSlice';
import { SVGElement } from '~types/general';

import { USER_PROFILE_NAVIGATION_TABS } from '../UserProfileDialog/constants';

import { StyledNavigationContainer } from './styled.components';

interface NavigationItemProps {
  icon: SVGElement;
  title: string;
  value: string;
  size?: 'small' | 'medium';
  showActive?: boolean;
}

export const NavigationItem = ({
  icon: Icon,
  title,
  size = 'medium',
  value,
  showActive = false,
}: NavigationItemProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { mainTab } = useAppSelector((state) => state.personalDetails);
  const isActiveTab = mainTab === value;

  const handleClick = (value: USER_PROFILE_NAVIGATION_TABS) => {
    dispatch(setMainTab({ tab: value }));
    dispatch(openDialog(DIALOGS.USER_PROFILE));
  };

  return (
    <StyledNavigationContainer
      flexRow
      key={title}
      onClick={() => handleClick(value as USER_PROFILE_NAVIGATION_TABS)}
      gap={2}
      alignCenter
      active={showActive && isActiveTab}
    >
      <Box
        flexRow
        css={{ color: isActiveTab ? '$accentPrimaryTwo' : '$textSecondaryOne' }}
      >
        <Icon width={20} height={20} />
      </Box>
      <Text
        level={size === 'medium' ? 'sm-3' : '16-24'}
        css={{
          color:
            showActive && isActiveTab ? '$accentPrimaryTwo' : '$textPrimaryOne',
          '&:hover': {
            color: '$accentPrimaryTwo',
          },
        }}
      >
        {localized(title)}
      </Text>
    </StyledNavigationContainer>
  );
};
