import React, { memo, useCallback } from 'react';

import { BetAmount } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { BetslipInputField } from '~components/molecules/Betslip/components/BetslipInfo/BetslipInputField';
import { BetslipOdds } from '~components/molecules/Betslip/components/BetslipInfo/BetslipOdds';
import { BetslipPayout } from '~components/molecules/Betslip/components/BetslipInfo/BetslipPayout';
import { BetslipPossibleWin } from '~components/molecules/Betslip/components/BetslipInfo/BetslipPossibleWin';
import {
  BETSLIP_VALUES_MAP_OPTIONS,
  DEFAULT_BALANCE_CHANGE,
} from '~components/molecules/Betslip/constants';
import { useFormatAmountWithCurrency } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useStakePayout } from '~hooks/useStakePayout';
import { useTranslation } from '~hooks/useTranslation';
import { DoubleChevronDown } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAdjustedBetAmount,
  setBetslipChanged,
  setSingleBetAmount,
  setStakePossibleWinLoadingMap,
} from '~store/slices/betslipSlice';
import {
  selectBalanceChangeById,
  selectIsSingleBetslip,
  selectPossibleWinLoadingById,
} from '~store/slices/selectors/betslip';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';
import { UUID } from '~types/uuid';
import { getBetTotalAmount } from '~utils/betslip';
import { isEmptyObject } from '~utils/objectHelpers';

import { BetslipBalancesPopover } from '../BetslipFooter/BetslipBalancesPopover';
import { BetslipMultipleBonus } from '../BetslipFooter/BetslipMultipleBonus';

import {
  StyledAdjustmentButton,
  StyledBetslipInputContainer,
} from './styled.components';

interface BetslipStakeAmountProps {
  index: number;
  amountMain?: string;
  amountBonus?: string;
  selectionId: UUID;
  odd: number;
  quickBet?: boolean;
}

const BetslipStakeAmount = ({
  index,
  quickBet,
  selectionId,
  odd,
  amountMain,
  amountBonus,
}: BetslipStakeAmountProps) => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  const balanceChange =
    useAppSelector(selectBalanceChangeById(selectionId)) ||
    DEFAULT_BALANCE_CHANGE;

  const isSingleBetslip = useAppSelector(selectIsSingleBetslip);
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isPossibleWinLoading = useAppSelector(
    selectPossibleWinLoadingById(selectionId),
  );
  const { siteConfiguration } = useAppSelector((state) => state.settings);
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  const amount = { main: amountMain, bonus: amountBonus };
  const totalStake = getBetTotalAmount(amount);

  const { showPayoutInfo, payoutInfoItems } = useStakePayout({
    stake: totalStake,
    stakeAfterTax: totalStake - (balanceChange?.stakeTax || 0),
    stakeTax: balanceChange?.stakeTax,
    stakeTaxPercent: siteConfiguration?.stakeTaxPercent,
    possibleWin: balanceChange?.totalPossibleWin,
    possibleIncomeTax: balanceChange?.possibleIncomeTax,
    incomeTaxPercent: siteConfiguration?.incomeTaxPercent,
    stakeTaxPayer: siteConfiguration?.taxPayer,
  });

  const handleAmountChange = useCallback(
    ({ id, amount }: { id: string; amount: BetAmount }) => {
      dispatch(setStakePossibleWinLoadingMap({ [id]: true }));
      dispatch(setSingleBetAmount({ id, amount }));
    },
    [amount],
  );

  const handleOddChange = () => {
    dispatch(setBetslipChanged(selectionId));
  };

  const handleSingleInputChange = useCallback(
    (value: string) =>
      handleAmountChange({ id: selectionId!, amount: { main: value } }),
    [amount],
  );

  const handleMainChange = useCallback(
    (value: string) =>
      handleAmountChange({
        id: selectionId!,
        amount: { bonus: amountBonus, main: value },
      }),
    [amount],
  );

  const handleBonusChange = useCallback(
    (value: string) =>
      handleAmountChange({
        id: selectionId!,
        amount: { main: amountMain, bonus: value },
      }),
    [amount],
  );

  const handleAdjustmentButtonClick = useCallback(() => {
    dispatch(setAdjustedBetAmount(amount));
    dispatch(
      setStakePossibleWinLoadingMap({
        [BETSLIP_VALUES_MAP_OPTIONS.ALL]: true,
      }),
    );
  }, [amount]);

  const {
    winBoostAmount,
    winBoostDescriptions,
    totalPossibleWin,
    possibleIncomeTax,
  } = balanceChange;
  const payout = totalStake > 0 ? totalPossibleWin - possibleIncomeTax : 0;
  const possibleWin = totalStake > 0 && totalPossibleWin ? totalPossibleWin : 0;

  return (
    <Box flexCol fullWidth gap={isMobileOrTablet ? 1 : 2}>
      {/* Stake input field */}
      <StyledBetslipInputContainer>
        <BetslipInputField
          mainAmount={amountMain}
          bonusAmount={amountBonus}
          handleSingleInputChange={handleSingleInputChange}
          handleMainChange={handleMainChange}
          handleBonusChange={handleBonusChange}
        />
        {index === 0 && !isSingleBetslip && (
          <StyledAdjustmentButton
            iconButton
            type={'button'}
            onClick={handleAdjustmentButtonClick}
          >
            <DoubleChevronDown width={16} height={16} />
          </StyledAdjustmentButton>
        )}
      </StyledBetslipInputContainer>

      {/* Stake */}
      <Box
        flexRow
        justifyContentBetween
        alignCenter
        css={{ mt: isMobileOrTablet ? '$1' : 'unset' }}
      >
        <Box flexRow alignCenter gap={1} css={{ color: '$textSecondaryOne' }}>
          <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.bet')}
          </Text>
          {!isEmptyObject(amount) && isUserLoggedIn && (
            <BetslipBalancesPopover balanceChange={balanceChange} />
          )}
        </Box>
        <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
          {getCommaSeparatedAmount(totalStake)}
        </Text>
      </Box>

      {/* Odds */}
      {!quickBet && <BetslipOdds odd={odd} handleOddChange={handleOddChange} />}

      {/* Possible Win */}
      {!showPayoutInfo && (
        <BetslipPossibleWin
          possibleWin={possibleWin}
          isPossibleWinLoading={isPossibleWinLoading}
        />
      )}
      {/* Payout */}
      {showPayoutInfo && balanceChange && (
        <BetslipPayout
          payout={payout}
          payoutInfoItems={payoutInfoItems}
          isPossibleWinLoading={isPossibleWinLoading}
        />
      )}
      {/* Bonus */}
      {!!+winBoostAmount && (
        <Box flexRow justifyContentBetween alignCenter>
          <Box
            css={{ color: '$textSecondaryOne' }}
            flexRow
            justifyContentStart
            alignCenter
            gap={2}
          >
            <Text underline level={isMobileOrTablet ? '11-16' : '13-16'}>
              {localized('betslip.bonus')}
            </Text>
            {winBoostDescriptions?.length ? (
              <BetslipMultipleBonus
                multipleBetBonusDescriptions={winBoostDescriptions}
              />
            ) : null}
          </Box>
          {isPossibleWinLoading ? (
            <Loader
              css={{
                width: '13px',
                height: '13px',
                color: '$yellowPrimary',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          ) : (
            <Text
              color="yellowPrimary"
              underline
              level={isMobileOrTablet ? '11-16' : '13-16'}
            >
              {getCommaSeparatedAmount(totalStake > 0 ? winBoostAmount : 0)}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(BetslipStakeAmount);
