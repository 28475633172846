import { PasswordRequirements } from '~components/atoms/PasswordRequirements';
import {
  InvisibleTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { PasswordCharacteristicsItem } from '~hooks/usePasswordCharacteristics';

interface PasswordRequirementsPopoverProps {
  isPasswordPopoverOpen: boolean;
  localizedCharacteristicsItems: PasswordCharacteristicsItem[];
}

export const PasswordRequirementsPopover = ({
  isPasswordPopoverOpen,
  localizedCharacteristicsItems,
}: PasswordRequirementsPopoverProps) => {
  return (
    <Popover
      isOpen={isPasswordPopoverOpen}
      css={{ backgroundColor: '$secondaryOne' }}
    >
      <PopoverContent
        side="bottom"
        sideOffset={16}
        align="center"
        css={{ backgroundColor: '$secondaryOne' }}
      >
        <PasswordRequirements
          localizedCharacteristicsItems={localizedCharacteristicsItems}
        />
      </PopoverContent>
      <PopoverTrigger asChild>
        <InvisibleTrigger side="bottom" />
      </PopoverTrigger>
    </Popover>
  );
};
