import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { InfoIconAlter } from '~icons';

import { AccountVerificationCriteria } from '../components';

export const AccountVerificationPopover = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box flexRow>
      <Popover
        isOpen={isOpen}
        handleOpenChange={(e) => {
          setIsOpen(e);
        }}
      >
        <PopoverContent
          side="top"
          sideOffset={16}
          align="center"
          tone="secondary"
          width={564}
        >
          <AccountVerificationCriteria />
        </PopoverContent>
        <PopoverTrigger asChild>
          <Box
            css={{
              cursor: 'pointer',
              color: '$textSecondaryOne',
              lineHeight: 0,
              '&:hover': { color: '$textPrimaryOne' },
            }}
          >
            <InfoIconAlter />
          </Box>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
