import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import {
  getLiveSelectionHeight,
  getSelectionWidth,
} from '~components/molecules/MainHighlights/helpers';
import { PREMATCH_SELECTION_HEIGHT } from '~constants/mainPage';
import { useMedia } from '~hooks/useMedia';
import { Selection } from '~types/events';

import { useMarketSelection } from './hooks/useMarketSelection';

const firstBorderStyle = {
  borderTopLeftRadius: '$6',
  borderBottomLeftRadius: '$6',
};

const lastBorderStyle = {
  borderTopRightRadius: '$6',
  borderBottomRightRadius: '$6',
};

interface MarketSelectionProps {
  selection: Selection | null | undefined;
  index: number;
  eventId: string;
  marketId: string | undefined;
  isTotalLabel?: boolean;
  totalLabel?: string;
  isLast?: boolean;
  isLive?: boolean;
  eventData: SportEventItem;
}

export const MarketSelection = ({
  selection,
  index,
  eventId,
  marketId,
  isTotalLabel,
  totalLabel,
  isLast,
  isLive,
  eventData,
}: MarketSelectionProps) => {
  const { isLaptop } = useMedia();
  const { selectionContent, handleSelectionClick, isInBetslip, odd } =
    useMarketSelection({
      selection,
      index,
      eventId,
      marketId,
      isTotalLabel,
      totalLabel,
      eventData,
    });

  return (
    <Box
      fullHeight
      flexRow
      alignCenter
      justifyCenter
      disabled={!odd}
      onClick={handleSelectionClick}
      className={isInBetslip ? 'active' : ''}
      css={{
        cursor: !odd ? 'not-allowed' : 'pointer',
        color: '$textSecondaryOne',
        height: `${
          isLive ? getLiveSelectionHeight(isLaptop) : PREMATCH_SELECTION_HEIGHT
        }px`,
        background: isTotalLabel ? '$secondaryOne' : '$primaryTwo',
        width: `${getSelectionWidth(isLaptop)}px`,
        ...(index === 0 ? firstBorderStyle : {}),
        ...(isLast ? lastBorderStyle : {}),
        '&:hover': {
          backgroundColor: odd && '$hoverSecondary',
        },
        '&.active': {
          backgroundColor: '$accentPrimaryOne',
          '& *': {
            color: '$textButtons',
          },
        },
      }}
    >
      {selectionContent}
    </Box>
  );
};
