import React, { MouseEvent } from 'react';

import { Text } from '~/components/atoms/Typography';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Select } from '~components/atoms/Select';
import { Separator } from '~components/atoms/Separator';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks';
import { CloseIcon, SettingsIcon } from '~icons';
import { useAppSelector } from '~store';
import { setCashOutAskType } from '~store/slices/openBetSlice';
import { selectIsUganda } from '~store/slices/selectors/settings';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { CASH_OUT_ASK_TYPES, getCashoutTypeOptions } from './constants';
import { useCashOut } from './useCashOut';

interface CashoutProps {
  noCloseIcon?: boolean;
  onCashoutProcessed?: () => void;
  onCancel?: (e: MouseEvent) => void;
  isBetHistory?: boolean;
}

export const CashOut = ({
  noCloseIcon = false,
  onCancel,
  onCashoutProcessed,
  isBetHistory = false,
}: CashoutProps) => {
  const isUganda = useAppSelector(selectIsUganda);
  const { isMobileOrTablet } = useMedia();

  const {
    availableCashout,
    cashOutAskType,
    currency,
    isLoading,
    dispatch,
    localized,
    handleClose,
    handleCashOut,
  } = useCashOut(noCloseIcon, onCashoutProcessed, isBetHistory);

  return (
    <Box flexCol gap={2} css={{ p: '$3 $4' }}>
      {!isMobileOrTablet && (
        <Box flexRow justifyContentBetween alignCenter>
          <Text level="16-24" textTransform="uppercase">
            {localized('betslip.cashOut')}
          </Text>
          {!noCloseIcon && (
            <Button
              iconButton
              noBorder
              noBackground
              css={{
                color: '$textSecondaryOne',
                width: '$4',
                height: '$4',
                minWidth: '$4',
                p: '0',
                '& > svg': { width: '$4', height: '$4' },
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          )}
        </Box>
      )}
      <Text
        level="12-16"
        color={!isUganda ? 'textPrimaryOne' : 'textSecondaryOne'}
      >
        {localized('betslip.cashOutLabel')}
      </Text>
      <Select
        css={{
          minWidth: '257px',
          '& > button': {
            width: '100%',
          },
          '@xs_sm': {
            backgroundColor: '$secondaryTwo',
            fontSize: '$xxs',
            lineHeight: '$20',
            borderRadius: '$4',
            p: '$1 0',
          },
        }}
        ariaLabel={localized('betslip.oddsAskAriaLabel')}
        value={cashOutAskType}
        onChange={(value) =>
          dispatch(setCashOutAskType(value as CASH_OUT_ASK_TYPES))
        }
        icon={<SettingsIcon />}
        options={getCashoutTypeOptions(localized)}
        contentCss={{
          '@xs_sm': {
            backgroundColor: '$secondaryTwo',
          },
        }}
      />
      <Separator
        verticalSpace={1}
        shrinkOut={4}
        css={{
          backgroundColor: '$primaryTwo',
        }}
      />
      <Box
        css={{
          backgroundColor: '$accentSecondaryOne',
          borderRadius: '$8',
          p: '$2',
        }}
        flexRow
        gap={1}
        alignCenter
        justifyContentBetween
      >
        <Text level="14-24" color="textPrimaryOne">
          {localized('betslip.cashOutAmount')}
        </Text>
        <Text level="14-24" color="yellowPrimary">
          {commaSeparatedNumber(availableCashout)} {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
      <Separator
        verticalSpace={1}
        shrinkOut={4}
        css={{
          backgroundColor: '$primaryTwo',
        }}
      />
      <Button
        onClick={handleCashOut}
        css={{ height: '40px' }}
        isLoading={isLoading}
      >
        <Text isButton level="14-20" fontWeight="bold">
          {localized('betslip.cashOut')}
        </Text>
      </Button>
      <Button
        onClick={onCancel ? onCancel : handleClose}
        variant="secondary"
        css={{ height: '40px', backgroundColor: '$secondaryTwo' }}
      >
        <Text level="14-20" fontWeight="bold">
          {localized('betslip.cancel')}
        </Text>
      </Button>
    </Box>
  );
};
