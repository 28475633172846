import { MainMarket } from '~api/market/types';
import { TOTAL_MARKET_NAME } from '~constants/mainPage';
import { Market, Selection } from '~types/events';

interface EventMarketHookProps {
  market: Market | undefined;
  mainMarket: MainMarket | undefined;
}

export const useEventMarket = ({
  mainMarket,
  market,
}: EventMarketHookProps) => {
  const isTotal = mainMarket?.name === TOTAL_MARKET_NAME;
  const marketId = market?.id;

  let totalMarketName: string | undefined;

  const emptyArray: null[] = [];

  emptyArray.length =
    mainMarket && mainMarket?.selections
      ? mainMarket.selections.length + (isTotal ? 1 : 0)
      : 0;
  emptyArray.fill(null);

  let selections: (Selection | null | undefined)[] = market?.selections?.length
    ? market?.selections
    : emptyArray;

  if (isTotal && market?.selections) {
    if (market.selections.length === 1) {
      const existingSelection = market.selections[0];

      if (existingSelection && existingSelection.name.startsWith('over')) {
        selections = [null, existingSelection, null];
      } else {
        selections = [null, null, existingSelection];
      }
    } else {
      selections = [null, ...market.selections];
    }

    totalMarketName = market.selections[0]?.name;

    if (totalMarketName) {
      totalMarketName = totalMarketName.split(' ')[1] || '-';
    }
  }

  return {
    selections,
    marketId,
    isTotal,
    totalMarketName,
  };
};
