import React from 'react';
import { CSS } from '@stitches/react';

import { Box } from '~components/atoms/Box';

interface StyledBaseInputProps {
  children: React.ReactNode;
  css?: CSS;
}

export const StyledBaseInputWrapper = ({
  children,
  css,
}: StyledBaseInputProps) => {
  return (
    <Box
      flexRow
      alignCenter
      fullWidth
      css={{
        ...css,
        backgroundColor: '$primaryTwo',
        borderRadius: '$6',
        p: '$1 $3',
        '& *': {
          backgroundColor: '$primaryTwo',
        },
        '@xs_sm': {
          height: '$7',
        },
      }}
    >
      {children}
    </Box>
  );
};
